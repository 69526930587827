import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Row, Col, Badge, Button, Image, Alert } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import ReactHtmlParser from 'react-html-parser';
import withUnmounted from '@ishawnwang/withunmounted';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { changePage } from 'store/actions';
import 'components/Pagine/Blog/BlogItems/BlogItems.css';

class BlogItems extends Component {

    state = {
        items: Array.from({ length: 3 }),
        hasMore: true
    };

    componentDidMount() {
        //console.log('BlogItems componentDidMount');
        if (this.props.sezione.datiPagina.elencoBlog && this.state.items.length >= this.props.sezione.datiPagina.elencoBlog.length) {
            if (this.state.hasMore === true) {
                this.setState({ hasMore: false });
            }
        }
    }

    componentDidUpdate() {
        //console.log('BlogItems componentDidUpdate');
        if (this.state.items.length >= this.props.sezione.datiPagina.elencoBlog.length) {
            if (this.state.hasMore === true) {
                this.setState({ hasMore: false });
            }
        }
    }

    fetchMoreData = () => {

        //console.log('dkjdkjdkd: '+this.state.items.length);

        if (this.props.sezione.datiPagina.elencoBlog) {
            if (this.state.items.length >= this.props.sezione.datiPagina.elencoBlog.length) {
                //console.log('fine!!!!: '+this.state.items.length);
                return;
            } else {
                setTimeout(() => {
                    this.setState({
                        items: this.state.items.concat(Array.from({ length: 1 }))
                    });
                }, 500);
            }
        } 

    };

    clean(obj) {
        let propNames = Object.getOwnPropertyNames(obj);
        for (var i = 0; i < propNames.length; i++) {
            let propName = propNames[i];
            if (obj[propName] === null || obj[propName] === undefined) {
            delete obj[propName];
            }
        }
    }

    onClickBlog = urlBlog => {
        let sezionePrecedente = this.props.sezione.sezionePrecedente;

        if (this.props.blogs && this.props.statico.pagine) {
            let paginaObj = Object.keys(this.props.statico.pagine)
                .filter(key => this.props.statico.pagine[key]["pagina"] === 'BlogPost')
                .reduce((obj, key) => {
                return this.props.statico.pagine[key];
                }, {});

            let blogObjD = this.props.blogs.blog;
            if (blogObjD) {
                let blogObjSearch = Object.keys(blogObjD)
                    .filter(key => blogObjD[key].urlseo_it === urlBlog)
                    .reduce((obj, key) => {
                        let newObj = blogObjD[key];
                        return newObj;
                    }, {});
                ////console.log(blogObjSearch);

                // escludo selezione da elencoBlog
                let blogObjNew = blogObjD
                .filter(key => key.urlseo_it !== urlBlog)
                .map(element => {
                    return element;
                }, {});
                

                let blogSelezionato = { ...paginaObj, elencoBlog: blogObjNew, blogSelezionato: blogObjSearch }
                this.props.changePage(`blog/${urlBlog}`, 'BlogPost', sezionePrecedente, blogSelezionato); // passo a pagina dettaglio blog
            }
        } 
    }

    onReset = () => {
        let sezionePrecedente = this.props.sezione.sezionePrecedente;

        let paginaObj = Object.keys(this.props.statico.pagine)
                .filter(key => this.props.statico.pagine[key]["pagina"] === 'Blog')
                .reduce((obj, key) => {
                return this.props.statico.pagine[key];
                }, {});

        let blogObjD = this.props.blogs.blog;
        let paginaBlog = { ...paginaObj, elencoBlog: blogObjD, blogSelezionato: null, categoriaSelezionata: null, categoriaColore: null, tagSelezionato: null }
        this.props.changePage(paginaObj.url, 'Blog', sezionePrecedente, paginaBlog, null, true);
    }

    blogData = () => {

        //console.log('ho chiamato blogData');

        let Aux = props => props.children;

        if(this.props.sezione.datiPagina.elencoBlog) {

            let elencoBlog = this.props.sezione.datiPagina.elencoBlog;

            let blog = elencoBlog.map((post, key) => {

                // controllo step infinite scroll
                if (key+1 > this.state.blogCurrentItems) return null;

                // controllo filtro per categoria
                if (this.props.sezione.datiPagina.categoriaSelezionata) {
                    /* //console.log(this.props.sezione.datiPagina.categoriaSelezionata);
                    //console.log(post.categoria_it); */
                    if (this.props.sezione.datiPagina.categoriaSelezionata !== post.categoria_it) return null;
                }

                let urlBlog = post.urlseo_it;

                let ImmagineBlog = '';
                if (post.immagine) {
                    ImmagineBlog = <Aux>
                        <div className="immagineCont d-flex justify-content-center align-items-center mr-4 mb-3"> 
                        <Image
                            src={`${process.env.PUBLIC_URL}/blog_images/thumbs/${post.immagine}`} 
                            className="immagineThumb"
                            onClick={() => this.onClickBlog(`${urlBlog}`)}
                            />
                        </div>
                    </Aux>;
                }

                let Tags = '';
                if (post.tags_it !== null) {
                    Tags = post.tags_it.split(',').map((tag, key) => {
                        return (
                            <Aux key={key}>
                                <Badge variant="info" className="m-1">{tag}</Badge>
                            </Aux>
                            );
                    });
                }

                let TagsCont = '';
                (Tags !== '') ? TagsCont = <div className="tagsCont">TAG: {Tags}</div> : TagsCont = '';

                let testoBreve = ReactHtmlParser(post.testoBreve_it);

                return (
                <Aux key={post.id}>
                    <Row noGutters>
                        <Col sm={12}>
                            <h4 className="mb-0">{post.titolo_it}</h4>
                            <h6><small><em>{post.dataBlog}</em></small> di {post.autore} <Badge className="text-white" style={{ backgroundColor : `${post.colore}`}}>{post.categoria_it}</Badge></h6>
                        </Col>
                    </Row>
                    <Row><Col sm={12} className="spacer15"></Col></Row>
                    <Row noGutters>
                        <Col sm={12}>
                            {ImmagineBlog}
                            <p className="fsize14 mb-3 descrizione">{testoBreve}</p>
                            {TagsCont}
                            <Button variant="light" size="sm" className="float-right fsize14 mt-4" onClick={() => this.onClickBlog(`${urlBlog}`)}>leggi di più »</Button>
                        </Col>
                    </Row>
                    <Row><Col sm={12} className="spacer30"></Col></Row>
                </Aux>
                );
            });

            return blog;
        } else {

            return null;
        }

    }

    render() {

        let Aux = props => props.children;

        let alert = '';
        if (this.props.sezione.datiPagina.categoriaSelezionata) {
            alert = <Alert variant="success">
                <Alert.Heading className="mb-0">Stai filtrando per <Badge variant="secondary" style={{ backgroundColor : `${this.props.sezione.datiPagina.coloreCategoria}`}} className="m-1">{this.props.sezione.datiPagina.categoriaSelezionata}</Badge> </Alert.Heading> 
                <p>
                    Se vuoi cancellare la selezione <Alert.Link onClick={this.onReset}>clicca qui</Alert.Link>
                </p>
            </Alert>;
        }

        if (this.props.sezione.datiPagina.tagSelezionato) {
            alert = <Alert variant="success">
                <Alert.Heading className="mb-0">Stai filtrando per questo tag <Badge variant="info" className="m-1">{this.props.sezione.datiPagina.tagSelezionato}</Badge> </Alert.Heading> 
                <p>
                    Se vuoi cancellare la selezione <Alert.Link onClick={this.onReset}>clicca qui</Alert.Link>
                </p>
            </Alert>;
        }

        let noResult = '';
        if (this.props.sezione.datiPagina.elencoBlog && this.props.sezione.datiPagina.elencoBlog.length === 0) {
            noResult = <p style={{textAlign: 'center'}}>
                    <b>Nessun articolo al momento</b>
                </p>;
        }

        return <Aux>
            <Col xs={{ span: 12, order: 12 }} md={{ span: 9, order: 12 }} className="blog pl-md-5">
                <h3 className="blogIcon"><FontAwesomeIcon icon={["far", "blog"]} /> Blog</h3>
                <Row><Col sm={12} className="spacer15"></Col></Row>
                <InfiniteScroll
                    dataLength={this.state.items.length} //This is important field to render the next data
                    next={this.fetchMoreData}
                    hasMore={this.state.hasMore}
                    hasChildren={false}
                    loader={<h4>Loading...</h4>}
                    endMessage={
                        <p style={{textAlign: 'center'}}>
                            <b></b>
                        </p>
                    }
                >
                    {alert}
                    {noResult}
                    {this.blogData()}
                </InfiniteScroll>
            </Col>
        </Aux>;
    }
}

const mapStateToProps = state => {
    return { 
        sezione: state.sezione,
        statico: state.statico,
        blogs: state.blog,
    };
}

export default compose( connect(mapStateToProps, { changePage } ), withUnmounted )(BlogItems);