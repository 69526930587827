import { CHANGE_PAGE } from 'store/actions/types';

const INITIAL_STATE = {
    nomePagina: '',
    sezionePagina: '',
    sezionePrecedente: '',
    urlPrecedente: '',
    datiPagina: {},
    prezzoId: ''
};

export default function(state = INITIAL_STATE, action) {
    switch (action.type) {
        case CHANGE_PAGE:
            return { ...state, 
                nomePagina: action.payload.nomePagina, 
                sezionePagina: action.payload.sezionePagina, 
                sezionePrecedente: action.payload.sezionePrecedente, 
                urlPrecedente: action.payload.urlPrecedente,
                datiPagina: action.payload.datiPagina,
                prezzoId: action.payload.prezzoId 
            };
        default:
            return state;
    }
}