import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import Slideshow from 'components/UI/Slideshow/Slideshow';
import BlogItems from 'components/Pagine/Blog/BlogItems/BlogItems';
import BlogFilters from 'components/Pagine/Blog/BlogFilters/BlogFilters';
import { changePage } from 'store/actions';
import 'components/Pagine/Blog/Blog.css';

class Blog extends Component {

    _isMounted = false;

    componentDidMount() {
        this._isMounted = true;

        //console.log('sono in componentDidMount blog');
        //console.log(this.props);

        if (this.props.blogs && this.props.statico.pagine) {
            
            let paginaObj = Object.keys(this.props.statico.pagine)
                    .filter(key => this.props.statico.pagine[key]["pagina"] === 'Blog')
                    .reduce((obj, key) => {
                    return this.props.statico.pagine[key];
                    }, {});
            if (paginaObj) {

                // PRENDO TUTTO I POST DEL BLOG E LI METTO IN elencoBlog di datiPagina per utilizzo futuro
                let elencoBlog = this.props.blogs.blog;
                let datiPagina = { ...paginaObj, elencoBlog: elencoBlog }
                
                this.props.changePage(paginaObj.url, 'Blog', 'Blog', datiPagina);
            }
        }
        // totale blog?
        if(this.props.blogs.blog) {
            //console.log(this.props.blogs.blog.length);
        }
    }

    componentDidUpdate() {
        //console.log('sono in componentDidUpdate blog');

        if (!this.props.sezione.nomePagina && this.props.statico.pagine) {
        let paginaObj = Object.keys(this.props.statico.pagine)
                .filter(key => this.props.statico.pagine[key]["pagina"] === 'Blog')
                .reduce((obj, key) => {
                return this.props.statico.pagine[key];
                }, {});
            //////console.log(paginaObj);
            if (paginaObj) {

                // PRENDO TUTTO I POST DEL BLOG E LI METTO IN elencoBlog di datiPagina per utilizzo futuro
                let elencoBlog = this.props.blogs.blog;
                let datiPagina = { ...paginaObj, elencoBlog: elencoBlog }
                
                this.props.changePage(paginaObj.url, 'Blog', 'Blog', datiPagina);
            }
        } else if(this.props.router.action === 'POP' && this.props.statico.pagine) {
        ////console.log('tornando alla pagina blog!');
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        //console.log(this._isMounted);
    }

    render() {
        let Aux = props => props.children;

        return (
        <Aux>
            <Slideshow sezioneSlide='blog' paginaSlide='blog' />
            <Container>
                <Row noGutters>
                    <Col sm={12} className="spacer30"></Col>
                    <BlogFilters />
                    <BlogItems />
                </Row>
            </Container>
        </Aux>
        );
    }
}

const mapStateToProps = state => {
    return { 
        router: state.router,
        sezione: state.sezione,
        statico: state.statico,
        blogs: state.blog,
    };
}

export default connect(mapStateToProps, { changePage } )(Blog);