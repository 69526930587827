import { CHANGE_FILTER } from 'store/actions/types';

const INITIAL_STATE = {
    selezionati: {
      cantina: '',
      vino: '',
      tipologia: '',
      formato: '',
      confezione: ''
    }
};

export default function(state = INITIAL_STATE, action) {
    switch (action.type) {
        case CHANGE_FILTER:
            return { ...state, selezionati: action.payload };
        default:
            return state;
    }
}