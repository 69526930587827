import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Image } from 'react-bootstrap';
import TrackVisibility from 'react-on-screen';
/* import { LazyLoadImage } from 'react-lazy-load-image-component'; */
import { TweenMax, TimelineMax, Expo } from 'gsap/TweenMax';
import { Grid, Cell } from 'styled-css-grid';
import Spinner from 'components/UI/Spinner/Spinner';
import { fetchCantine, changePage } from 'store/actions';
import 'components/Pagine/ElencoCantine/ElencoCantine.css';

class ElencoCantine extends Component {
	constructor(props) {
		super(props);
		// reference to the animation
		this.t1 = new TimelineMax({ paused: true, delay: 0.5 });
		this.t2 = new TimelineMax({ paused: true, delay: 0.5 });
		this.t3 = new TimelineMax({ paused: true, delay: 0.5 });
		this.t4 = new TimelineMax({ paused: true, delay: 0.5 });
		this.t5 = new TimelineMax({ paused: true, delay: 0.5 });
		this.t6 = new TimelineMax({ paused: true, delay: 0.5 });
	}

	state = {
		loading: false,
	};

	componentDidMount() {
		if (this.props.cantine === null) {
			// mostro Spinner
			this.setState({ loading: true });
			// recupero dati cantine
			//this.props.fetchCantine();
		} else {
			this.startTween();
		}
	}

	componentDidUpdate() {
		if (this.props.cantine) {
			this.startTween();
		}
	}

	startTween() {
		// ANIMAZIONI BOX CANTINE
		this.t1.to('#boxCantina1', 0.5, {
			scale: 1.2,
			transformOrigin: 'center center',
			ease: Expo.EaseOut,
		});
		this.t1.fromTo(
			'#titoloCantina1',
			0.5,
			{ opacity: 0, x: '50' },
			{ opacity: 1, x: 0, ease: Expo.easeOut },
			0
		);
		this.t1.to('#logoCantina1', 0.2, { marginTop: 0, ease: Expo.EaseOut }, 0);
		this.t2.to('#boxCantina2', 0.5, {
			scale: 1.2,
			transformOrigin: 'center center',
			ease: Expo.easeOut,
		});
		this.t2.fromTo(
			'#titoloCantina2',
			0.5,
			{ opacity: 0, y: '50' },
			{ opacity: 1, y: 0, ease: Expo.easeOut },
			0
		);
		this.t2.to('#logoCantina2', 0.2, { marginTop: 0, ease: Expo.EaseOut }, 0);
		this.t3.to('#boxCantina3', 0.5, {
			scale: 1.2,
			transformOrigin: 'center center',
			ease: Expo.easeOut,
		});
		this.t3.fromTo(
			'#titoloCantina3',
			0.5,
			{ opacity: 0, y: '50' },
			{ opacity: 1, y: 0, ease: Expo.easeOut },
			0
		);
		this.t3.to('#logoCantina3', 0.2, { marginTop: 0, ease: Expo.EaseOut }, 0);
		this.t4.to('#boxCantina4', 0.5, {
			scale: 1.2,
			transformOrigin: 'center center',
			ease: Expo.easeOut,
		});
		this.t4.fromTo(
			'#titoloCantina4',
			0.5,
			{ opacity: 0, x: '50' },
			{ opacity: 1, x: 0, ease: Expo.easeOut },
			0
		);
		this.t4.to('#logoCantina4', 0.2, { marginTop: 0, ease: Expo.EaseOut }, 0);
		this.t5.to('#boxCantina5', 0.5, {
			scale: 1.2,
			transformOrigin: 'center center',
			ease: Expo.easeOut,
		});
		this.t5.fromTo(
			'#titoloCantina5',
			0.5,
			{ opacity: 0, x: '50' },
			{ opacity: 1, x: 0, ease: Expo.easeOut },
			0
		);
		this.t5.to('#logoCantina5', 0.2, { marginTop: 0, ease: Expo.EaseOut }, 0);
		this.t6.to('#boxCantina6', 0.5, {
			scale: 1.2,
			transformOrigin: 'center center',
			ease: Expo.easeOut,
		});
		this.t6.fromTo(
			'#titoloCantina6',
			0.5,
			{ opacity: 0, x: '-50' },
			{ opacity: 1, x: 0, ease: Expo.easeOut },
			0
		);
		this.t6.to('#logoCantina6', 0.2, { marginTop: 0, ease: Expo.EaseOut }, 0);
	}

	animateComp = (isVisible, tweenToAnimate) => {
		//console.log('aaa'+isVisible);
		if (isVisible === '1') {
			tweenToAnimate.play();
		} else {
			tweenToAnimate.reverse();
		}
	};

	onClickImage = (urlCantina) => {
		//console.log('[ElencoCantine] '+urlCantina);
		if (this.props.cantine) {
			let cantinaObj = Object.keys(this.props.cantine)
				.filter((key) => this.props.cantine[key]['url'] === urlCantina)
				.reduce((obj, key) => {
					return this.props.cantine[key];
				}, {});
			if (cantinaObj) {
				this.props.changePage(
					`${cantinaObj.url}`,
					'Cantina',
					'Homepage',
					cantinaObj
				); // passo a pagina elenco vini cantina
			}
		}
	};

	hideImage = (idBoxCantina) => {
		//console.log(idBoxCantina);
		TweenMax.to(`#${idBoxCantina} .colore`, 1, { opacity: 1 });
	};

	showImage = (idBoxCantina) => {
		TweenMax.to(`#${idBoxCantina} .colore`, 1, { opacity: 0 });
	};

	renderContent() {
		let arrayCantine = this.props.cantine.map(
			({ id, cantina, immaginehome, immaginehome2, logo, url }, key) => {
				let immaginiVilla;

				if (immaginehome2) {
					immaginiVilla = (
						<div id={`boxCantina${id}`}>
							<Image
								src={`${
									process.env.PUBLIC_URL
								}/images/cantine/${immaginehome}.jpg`}
								alt={cantina}
								className='immaginePrincipale colore'
								onClick={() => this.onClickImage(`${url}`)}
								onMouseEnter={() => this.hideImage(`boxCantina${id}`)}
								onMouseLeave={() => this.showImage(`boxCantina${id}`)}
							/>
							<Image
								src={`${
									process.env.PUBLIC_URL
								}/images/cantine/${immaginehome}_grigio.jpg`}
								alt={cantina}
								className='immaginePrincipale grigio'
							/>
							<Image
								src={`${
									process.env.PUBLIC_URL
								}/images/cantine/${immaginehome2}.jpg`}
								alt={cantina}
								className='immagineAlternativa colore'
								onClick={() => this.onClickImage(`${url}`)}
								onMouseEnter={() => this.hideImage(`boxCantina${id}`)}
								onMouseLeave={() => this.showImage(`boxCantina${id}`)}
							/>
							<Image
								src={`${
									process.env.PUBLIC_URL
								}/images/cantine/${immaginehome2}_grigio.jpg`}
								alt={cantina}
								className='immagineAlternativa grigio'
							/>
						</div>
					);
				} else {
					immaginiVilla = (
						<div id={`boxCantina${id}`}>
							<Image
								src={`${
									process.env.PUBLIC_URL
								}/images/cantine/${immaginehome}.jpg`}
								alt={cantina}
								className='immaginePrincipale colore'
								onClick={() => this.onClickImage(`${url}`)}
								onMouseEnter={() => this.hideImage(`boxCantina${id}`)}
								onMouseLeave={() => this.showImage(`boxCantina${id}`)}
							/>
							<Image
								src={`${
									process.env.PUBLIC_URL
								}/images/cantine/${immaginehome}_grigio.jpg`}
								alt={cantina}
								className='immaginePrincipale grigio'
							/>
						</div>
					);
				}

				return (
					<div className='boxCantina'>
						<Image
							fluid
							src={`${process.env.PUBLIC_URL}/images/cantine/loghi/${logo}`}
							alt='Terra Moretti'
							className='logoCantina'
							id={`logoCantina${id}`}
						/>
						{/* <h1 className="fsemib fsize25 titoloCantina" id={`titoloCantina${id}`}>{cantina}</h1> */}
						{immaginiVilla}
					</div>
				);
			}
		);

		let ComponentToTrack = ({ isVisible, ...props }) => {
			let style = {
				opacity: isVisible ? '1' : '0',
			};

			if (isVisible && props.animateToTrack.timeline !== null) {
				props.animateToTrack.play();
			}

			const divProps = Object.assign({}, props);
			delete divProps.animateToTrack;

			return (
				<div {...divProps} style={style}>
					{divProps.cantina}
				</div>
			);
		};

		return (
			<Grid
				flow='row dense'
				id='gridCantine'
				columns={6}
				justifyContent='space-around'
				gap='12px'>
				<Cell width={2} height={1} className='gridCantina uno' id='Cantina1'>
					<TrackVisibility once partialVisibility className='gruppoCantine'>
						<ComponentToTrack
							animateToTrack={this.t1}
							onMouseEnter={() => this.t1.play()}
							onMouseLeave={() => this.t1.reverse()}
							cantina={arrayCantine[0]}
						/>
					</TrackVisibility>
				</Cell>
				<Cell width={1} height={2} className='gridCantina due' id='Cantina2'>
					<TrackVisibility once partialVisibility className='gruppoCantine'>
						<ComponentToTrack
							animateToTrack={this.t2}
							onMouseEnter={() => this.t2.play()}
							onMouseLeave={() => this.t2.reverse()}
							cantina={arrayCantine[1]}
						/>
					</TrackVisibility>
				</Cell>
				<Cell width={1} height={2} className='gridCantina tre' id='Cantina3'>
					<TrackVisibility once partialVisibility className='gruppoCantine'>
						<ComponentToTrack
							animateToTrack={this.t3}
							onMouseEnter={() => this.t3.play()}
							onMouseLeave={() => this.t3.reverse()}
							cantina={arrayCantine[2]}
						/>
					</TrackVisibility>
				</Cell>
				<Cell
					width={2}
					height={1}
					className='gridCantina quattro'
					id='Cantina4'>
					<TrackVisibility once partialVisibility className='gruppoCantine'>
						<ComponentToTrack
							animateToTrack={this.t4}
							onChange={(isVisible) => this.animateComp(isVisible, this.t4)}
							onMouseEnter={() => this.t4.play()}
							onMouseLeave={() => this.t4.reverse()}
							cantina={arrayCantine[3]}
						/>
					</TrackVisibility>
				</Cell>
				<Cell width={2} height={1} className='gridCantina cinque' id='Cantina6'>
					<TrackVisibility once partialVisibility className='gruppoCantine'>
						<ComponentToTrack
							animateToTrack={this.t6}
							onMouseEnter={() => this.t6.play()}
							onMouseLeave={() => this.t6.reverse()}
							cantina={arrayCantine[5]}
						/>
					</TrackVisibility>
				</Cell>
				<Cell width={2} height={1} className='gridCantina sei' id='Cantina5'>
					<TrackVisibility once partialVisibility className='gruppoCantine'>
						<ComponentToTrack
							animateToTrack={this.t5}
							onMouseEnter={() => this.t5.play()}
							onMouseLeave={() => this.t5.reverse()}
							cantina={arrayCantine[4]}
						/>
					</TrackVisibility>
				</Cell>
			</Grid>
		);
	}

	render() {
		let elencoCantine = this.props.cantine ? (
			this.renderContent()
		) : (
			<Spinner loading={this.state.loading} classStyles='spinnerCentered' />
		);

		return (
			<Container>
				<Row noGutters className='cantineContainer'>
					{elencoCantine}
				</Row>
			</Container>
		);
	}
}

const mapStateToProps = (state) => {
	return { cantine: state.catalogo.cantine };
};

export default connect(
	mapStateToProps,
	{ fetchCantine, changePage }
)(ElencoCantine);
