import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Col, Form, Button } from 'react-bootstrap';
import { reduxForm, Field } from 'redux-form';
import {
	changePage,
	fetchVini,
	fetchFormati,
	fetchConfezioni,
	changeFilter,
} from 'store/actions';
import 'components/UI/Filtri/Filtri.css';

/* const ReduxFormControl = ({input, label, values, defaultValue}) => {
  //console.log('defaultvalue: '+defaultValue);
  return (
  <select className="form-control" defaultValue={defaultValue} onChange={input.onChange} onBlur={() => input.onBlur(input.value)} {...input}>
    <option value="">-- {label} --</option>
    {values}
  </select>
  );
} */

const renderSelect = (props) => {
	return (
		<Form.Control
			as='select'
			name='cantina'
			value={props.input.value}
			onChange={props.input.onChange}
			onBlur={() => props.input.onBlur(props.input.value)}>
			<option>-- {props.label} --</option>
			{props.options}
		</Form.Control>
	);
};

class Filtri extends Component {
	state = {
		validated: false,
	};

	componentDidMount() {
		// elenco vini
		if (this.props.vini === null) {
			//console.log('[Filtri] componentDidMount:: recupero vini');
			this.props.fetchVini();
		}

		// elenco formati
		if (this.props.formati === null) {
			//console.log('[Filtri] componentDidMount:: recupero formati');
			this.props.fetchFormati();
		}

		// elenco confezioni
		if (this.props.confezioni === null) {
			//console.log('[Filtri] componentDidMount:: recupero confezioni');
			this.props.fetchConfezioni();
		}
	}

	shouldComponentUpdate(nextProps) {
		return nextProps.value !== this.props.value;
	}

	componentWillUnmount() {}

	sort = (prop, arr) => {
		prop = prop.split('.');
		const len = prop.length;

		arr.sort(function(a, b) {
			let i = 0;
			while (i < len) {
				a = a[prop[i]];
				b = b[prop[i]];
				i++;
			}
			if (a < b) {
				return -1;
			} else if (a > b) {
				return 1;
			} else {
				return 0;
			}
		});
		return arr;
	};

	onRenderCantine = () => {
		let cantineList = null;
		if (this.props.cantine) {
			const newArray = [...this.props.cantine];
			const newArrayCantine = this.sort('cantina', newArray);
			cantineList = newArrayCantine.map((value) => {
				return (
					<option key={value.id} value={value.cantina}>
						{value.cantina}
					</option>
				);
			});
		}
		return cantineList;
	};

	onRenderTipologie = () => {
		let tipologieList = null;
		if (this.props.tipologie) {
			const newArray = [...this.props.tipologie];
			const newArrayTipologie = this.sort('tipologia_en', newArray);
			tipologieList = newArrayTipologie.map((value) => {
				return (
					<option key={value.id} value={value.tipologia}>
						{value.tipologia_it}
					</option>
				);
			});
		}
		return tipologieList;
	};

	onRenderVini = () => {
		let viniList = null;
		if (this.props.vini) {
			const newArray = [...this.props.vini];
			const newArrayVini = this.sort('Vino', newArray);
			viniList = newArrayVini.map((value) => {
				let vinoNome = `${value.Vino} (${value.Cantina})`;
				return (
					<option key={value.ID} value={value.UrlSEO}>
						{vinoNome}
					</option>
				);
			});
		}
		return viniList;
	};

	onRenderFormati = () => {
		let formatiList = null;
		if (this.props.formati) {
			const newArray = [...this.props.formati];
			const newArrayFormati = this.sort('id', newArray);
			formatiList = newArrayFormati.map((value) => {
				return (
					<option key={value.id} value={value.formato}>
						{value.formato} ml
					</option>
				);
			});
		}
		return formatiList;
	};

	onRenderConfezioni = () => {
		let confezioniList = null;
		if (this.props.confezioni) {
			const newArray = [...this.props.confezioni];
			const newArrayConfezioni = this.sort('confezione', newArray);
			confezioniList = newArrayConfezioni.map((value) => {
				return (
					<option key={value.id} value={value.confezione}>
						{value.confezione}
					</option>
				);
			});
		}
		return confezioniList;
	};

	onSubmit = (formProps) => {
		/* //console.log(formProps); */
		this.props.changeFilter(formProps);
	};

	onClearChanges = () => {
		const { reset } = this.props;
		reset();
		this.props.changeFilter(null);
	};

	onChangeCantina = (formProps) => {
		////console.log(formProps);
	};

	render() {
		let Aux = (props) => props.children;

		const { validated } = this.state.validated;
		const { handleSubmit } = this.props;

		return (
			<Aux>
				<Container className='filtri'>
					<Form
						noValidate
						validated={validated}
						onSubmit={handleSubmit(this.onSubmit)}
						className='boxFiltri'
						name='filtriForm'>
						<Form.Row className='d-flex justify-content-between p-4'>
							<Col sm={6} lg={3}>
								<Form.Group>
									<Field
										name='cantina'
										label='wineries'
										options={this.onRenderCantine()}
										component={renderSelect}
									/>
									{/* <Field 
                    name="cantina" 
                    id="selectCantina" 
                    component={ReduxFormControl} 
                    values={this.onRenderCantine()} 
                    label="prodotti" 
                    defaultValue={this.props.initialValues.cantina.value} 
                  /> */}
								</Form.Group>
							</Col>
							{/* <Col sm={6} lg={2}>
                <Form.Group>
                  <Field name="vino" id="selectVino" component="select" className="form-control" disabled>
                    <option value="">-- products --</option>
                    {this.onRenderVini()}
                  </Field>
                </Form.Group>
              </Col>
              <Col sm={6} lg={2}>
                <Form.Group>
                  <Field name="tipologia" id="selectTipologia" component="select" className="form-control">
                    <option value="">-- types --</option>
                    {this.onRenderTipologie()}
                  </Field>
                </Form.Group>
              </Col>
              <Col sm={6} lg={2}>
                <Form.Group>
                  <Field name="formato" id="selectFormato" component="select" className="form-control">
                    <option value="">-- formats --</option>
                    {this.onRenderFormati()}
                  </Field>
                </Form.Group>
              </Col>
              <Col sm={6} lg={2}>
                <Form.Group>
                  <Field name="confezione" id="selectConfezione" component="select" className="form-control">
                    <option value="">-- packages --</option>
                    {this.onRenderConfezioni()}
                  </Field>
                </Form.Group>
              </Col> */}
							<Col sm={6} lg={2}>
								<Button variant='success' type='submit' className='inviaFiltro'>
									Filter
								</Button>
								<Button
									variant='secondary'
									type='reset'
									className='resettaFiltro'
									onClick={this.onClearChanges}>
									Reset
								</Button>
							</Col>
						</Form.Row>
					</Form>
				</Container>
			</Aux>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		sezione: state.sezione,
		cantine: state.catalogo.cantine,
		tipologie: state.catalogo.tipologie,
		vini: state.catalogo.vini,
		formati: state.catalogo.formati,
		confezioni: state.catalogo.confezioni,
		initialValues: state.filtri.selezionati,
	};
};

Filtri = reduxForm({
	form: 'filtriForm', // a unique name for this form,
	enableReinitialize: true,
})(Filtri);

Filtri = connect(
	mapStateToProps,
	{ changePage, fetchVini, fetchFormati, fetchConfezioni, changeFilter }
)(Filtri);

export default Filtri;
