import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import reduxThunk from 'redux-thunk';
import createRootReducer from 'store/reducers';

// basename:'/terramoretti/build'
export const history = createBrowserHistory();

export default function configureStore(preloadedState) {

  const store = createStore(
    createRootReducer(history), // root reducer with router state
    preloadedState,
    compose(
      applyMiddleware(
        routerMiddleware(history), // for dispatching history actions
        reduxThunk // async actions creator
      )/* , 
      window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__() */
    ),
  );

  return store;
}