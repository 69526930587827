import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router'; // react-router v4
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSearch } from '@fortawesome/pro-light-svg-icons';
import {
	faEye,
	faEyeSlash,
	faBlog,
	faFilter,
} from '@fortawesome/pro-regular-svg-icons';
import ScrollToTop from 'components/hoc/ScrollToTop';
import withPermissions from 'components/hoc/withPermissions';
import { isAuth } from 'store/actions';
import Header from 'components/UI/Header/Header';
import Footer from 'components/UI/Footer/Footer';
import Login from 'components/Pagine/Login/Login';
import Homepage from 'components/Pagine/Homepage/Homepage';
import Cantina from 'components/Pagine/Cantina/Cantina';
import DettaglioVino from 'components/Pagine/Cantina/DettaglioVino/DettaglioVino';
import Prezzi from 'components/Pagine/Prezzi/Prezzi';
import Blog from 'components/Pagine/Blog/Blog';
import BlogPost from 'components/Pagine/Blog/BlogPost';
import Forbidden from 'components/Pagine/403/Forbidden';
import 'components/App.css';

/* let deferredPrompt = null; */
// FONT AWESOME INIZIALIZZAZIONE
library.add(faSearch, faEye, faEyeSlash, faBlog, faFilter);

class App extends Component {
	componentWillMount() {
		// controllo subito il login
	}

	componentDidMount() {
		window.addEventListener('beforeinstallprompt', function(event) {
			//console.log('beforeinstallprompt fired');
		});

		//console.log('[App] componentDidMount::');

		// import font bundle
		import('utility/font.js');

		// autenticato?
		this.props.isAuth();
	}

	componentDidUpdate() {
		//console.log('[App] componentDidUpdate::');
		// autenticato?
		if (this.props.auth.login !== null && !this.props.auth.authenticated) {
			let NOW = parseInt(Date.now() / 1000);
			if (this.props.auth.login.token) {
				if (NOW < this.props.auth.login.validita) {
					this.props.isAuth();
				}
			}
		}
	}

	render() {
		const Aux = (props) => props.children;

		let init = '';
		// NON AUTENTICATO
		if (!this.props.auth.authenticated) {
			init = (
				<Aux>
					<Route path='/' component={Login} />
				</Aux>
			);
		} else {
			// AUTENTICATO
			let dettaglioVino = '';
			// fix back browser da dettaglio vino a prezzi
			if (this.props.router.location.pathname !== '/prezzi') {
				dettaglioVino = (
					<Route exact path='/:cantina/:vino' component={DettaglioVino} />
				);
			}
			init = (
				<Aux>
					<Header />
					<Switch>
						<Route exact path='/' component={Homepage} />
						<Route path='/prezzi' component={Prezzi} />
						<Route exact path='/403' component={Forbidden} />
						<Route exact path='/blog' component={Blog} />
						<Route exact path='/blog/:idblog' component={BlogPost} />
						<Route
							exact
							path='/:cantina'
							component={withPermissions(Cantina)}
						/>
						{dettaglioVino}
					</Switch>
					<Footer />
				</Aux>
			);
		}

		return (
			<ScrollToTop>
				<div className='root'>{init}</div>
			</ScrollToTop>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		router: state.router,
		auth: state.auth,
		sezione: state.sezione,
		cantine: state.catalogo.cantine,
		statico: state.statico,
	};
};

export default connect(
	mapStateToProps,
	{ isAuth }
)(App);
