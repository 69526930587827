import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Badge, Button, Image } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactHtmlParser from 'react-html-parser';
import InfiniteScroll from 'react-infinite-scroll-component';
import { PopupboxManager, PopupboxContainer } from 'react-popupbox';
import { changePage } from 'store/actions';
import 'components/Pagine/Blog/BlogDetail/BlogDetail.css';

class BlogDetail extends Component {

    state = {
        items: Array.from({ length: 3 }),
        hasMore: true
    };

    componentDidMount() {

        if (this.props.sezione.datiPagina.elencoBlog && this.state.items.length >= this.props.sezione.datiPagina.elencoBlog.length) {
            if (this.state.hasMore === true) {
                this.setState({ hasMore: false });
            }
        }

        if (this.props.blogs && this.props.statico.pagine) {
            let paginaObj = Object.keys(this.props.statico.pagine)
                .filter(key => this.props.statico.pagine[key]["pagina"] === 'BlogPost')
                .reduce((obj, key) => {
                return this.props.statico.pagine[key];
                }, {});

            let blogObjD = this.props.blogs.blog;
            let currentPath = this.props.router.location.pathname.split('/');
            let urlBlog = currentPath[2];
            if (blogObjD) {
                let blogObjSearch = Object.keys(blogObjD)
                    .filter(key => blogObjD[key].urlseo_en === urlBlog)
                    .reduce((obj, key) => {
                        return blogObjD[key];
                    }, {});
                ////console.log(blogObjSearch);

                // escludo selezione da elencoBlog
                let blogObjNew = blogObjD
                .filter(key => key.urlseo_en !== urlBlog)
                .map(element => {
                    return element;
                }, {});

                let blogSelezionato = { ...paginaObj, blogElenco: blogObjNew, blogSelezionato: blogObjSearch }
                this.props.changePage(`blog/${urlBlog}`, 'BlogPost', '', blogSelezionato); // passo a pagina dettaglio blog
            }
        }
    }

    componentDidUpdate() {
        //console.log('BlogItems componentDidUpdate');
        if (this.state.items.length >= this.props.sezione.datiPagina.elencoBlog.length) {
            if (this.state.hasMore === true) {
                this.setState({ hasMore: false });
            }
        }
    }

    fetchMoreData = () => {

        //console.log('dkjdkjdkd: '+this.state.items.length);
        //console.log(this.props.sezione.datiPagina.elencoBlog);
        if (this.props.sezione.datiPagina.elencoBlog) {
            //console.log(this.state.items.length);
            //console.log(this.props.sezione.datiPagina.elencoBlog.length);
            if (this.state.items.length >= this.props.sezione.datiPagina.elencoBlog.length) {
                //console.log('fine!!!!: '+this.state.items.length);
                return;
            } else {
                setTimeout(() => {
                    this.setState({
                        items: this.state.items.concat(Array.from({ length: 1 }))
                    });
                }, 500);
            }
        } 

    };

    openPopupbox = immagineZoom => {
        const content = <Image
            src={`${process.env.PUBLIC_URL}/blog_images/zoom/${immagineZoom}`} 
            alt=''
            className="blogZoom"
        />;
        PopupboxManager.open({
            content,
            config: {
            fadeIn: true,
            fadeInSpeed: 500
            }
        })
    }

    blogDataSelezionato = () => {

        //console.log('ho chiamato blogDataSelezionato');

        let Aux = props => props.children;

        if (this.props.sezione.datiPagina.blogSelezionato) {

            let postData = this.props.sezione.datiPagina.blogSelezionato;
            //console.log(postData.tags_en);

            let ImmagineBlog = '';
            if (postData.immagine) {
                ImmagineBlog = <Aux>
                    <div className="immagineContBig d-flex justify-content-start align-items-center mb-3 mr-3"> 
                    <Image
                        src={`${process.env.PUBLIC_URL}/blog_images/thumbs/${postData.immagine}`} 
                        className="immagineBig"
                        onClick={() => this.openPopupbox(postData.immagine)}
                        fluid
                        />
                    </div>
                </Aux>;
            }

            let Tags = '';
            if (postData.tags_en !== null) {
                Tags = postData.tags_en.split(',').map((tag, key) => {
                    return (
                        <Aux key={key}>
                            <Badge variant="info" className="m-1">{tag}</Badge>
                        </Aux>
                        );
                });
            }

            let TagsCont = '';
            (Tags !== '') ? TagsCont = <Col sm={12} className="tagsCont">TAG: {Tags}</Col> : TagsCont = '';

            let testoLungo = ReactHtmlParser(postData.testo_en);

            return (
                <Aux>
                    <Row noGutters>
                        <Col sm={12}>
                            <h4 className="mb-0">{postData.titolo_en}</h4>
                            <h6><small><em>{postData.dataBlog}</em></small> by {postData.autore} <Badge className="text-white" style={{ backgroundColor : `${postData.colore}`}}>{postData.categoria_en}</Badge></h6>
                        </Col>
                    </Row>
                    <Row noGutters><Col sm={12} className="spacer15"></Col></Row>
                    <Row noGutters>
                        <Col sm={12}>
                            {TagsCont}
                            {ImmagineBlog}
                            <p className="fsize14 mb-3">{testoLungo}</p>
                        </Col>
                    </Row>
                    <Row><Col sm={12} className="spacer30"></Col></Row>
                </Aux>
                );

        } else {

            return null;
        }

    }

    blogDataElenco = () => {

        //console.log('ho chiamato blogDataElenco');

        let Aux = props => props.children;

        if (this.props.blogs.blog) {

            let blog = this.props.blogs.blog.map((post, key) => {

                // controllo step infinite scroll
                if (key+1 > this.state.blogCurrentItems) return null;

                // escludo post selezionato
                if(this.props.sezione.datiPagina.blogSelezionato && post.urlseo_en === this.props.sezione.datiPagina.blogSelezionato.urlseo_en) {
                    return null;
                }

                let ImmagineBlog = '';
                if (post.immagine) {
                    ImmagineBlog = <Aux>
                        <div className="immagineCont d-flex justify-content-center align-items-center mr-4 mb-3"> 
                        <Image
                            src={`${process.env.PUBLIC_URL}/blog_images/thumbs/${post.immagine}`} 
                            className="immagineThumb"
                            onClick={() => this.onClickBlog(`${urlBlog}`)}
                            />
                        </div>
                    </Aux>;
                }

                let Tags = '';
                if (post.tags_en !== null) {
                    Tags = post.tags_en.split(',').map((tag, key) => {
                        return (
                            <Aux key={key}>
                                <Badge variant="info" className="m-1">{tag}</Badge>
                            </Aux>
                            );
                    });
                }

                let TagsCont = '';
                (Tags !== '') ? TagsCont = <div className="tagsCont">TAG: {Tags}</div> : TagsCont = '';

                let testoBreve = ReactHtmlParser(post.testoBreve_en);

                let urlBlog = post.urlseo_en;

                return (
                <Aux key={post.id}>
                    <Row noGutters>
                        <Col sm={12}>
                            <h4 className="mb-0">{post.titolo_en}</h4>
                            <h6><small><em>{post.dataBlog}</em></small> by {post.autore} <Badge className="text-white" style={{ backgroundColor : `${post.colore}`}}>{post.categoria_en}</Badge></h6>
                        </Col>
                    </Row>
                    <Row><Col sm={12} className="spacer15"></Col></Row>
                    <Row noGutters>
                        <Col sm={12}>
                            {ImmagineBlog}
                            <p className="fsize14 mb-3 descrizione">{testoBreve}</p>
                            {TagsCont}
                            <Button variant="light" size="sm" className="float-right fsize14 mt-4" onClick={() => this.onClickBlog(`${urlBlog}`)}>read more »</Button>
                        </Col>
                    </Row>
                    <Row><Col sm={12} className="spacer30"></Col></Row>
                </Aux>
                );
            });

            return blog;
        } else {

            return null;
        }

    }

    
    onClickBlog = urlBlog => {
        let sezionePrecedente = this.props.sezione.sezionePrecedente;

        if (this.props.blogs && this.props.statico.pagine) {
            let paginaObj = Object.keys(this.props.statico.pagine)
                .filter(key => this.props.statico.pagine[key]["pagina"] === 'BlogPost')
                .reduce((obj, key) => {
                return this.props.statico.pagine[key];
                }, {});

            let blogObjD = this.props.blogs.blog;
            if (blogObjD) {
                let blogObjSearch = Object.keys(blogObjD)
                    .filter(key => blogObjD[key].urlseo_en === urlBlog)
                    .reduce((obj, key) => {
                        return blogObjD[key];
                    }, {});
                //console.log(blogObjSearch);

                // escludo selezione da elencoBlog
                let blogObjNew = blogObjD
                .filter(key => key.urlseo_en !== urlBlog)
                .map(element => {
                    return element;
                }, {});

                let blogSelezionato = { ...paginaObj, elencoBlog: blogObjNew, blogSelezionato: blogObjSearch }
                this.props.changePage(`blog/${urlBlog}`, 'BlogPost', sezionePrecedente, blogSelezionato); // passo a pagina dettaglio blog
            }
        } 
    }

    render() {

        let Aux = props => props.children;

        return <Aux>
            <Col xs={{ span: 12, order: 12 }} md={{ span: 9, order: 12 }} className="blog pl-md-5">
                <h3 className="blogIcon"><FontAwesomeIcon icon={["far", "blog"]} /> Blog</h3>
                <Row><Col sm={12} className="spacer15"></Col></Row>
                {this.blogDataSelezionato()}
                <div className="border-top my-3"></div>
                <InfiniteScroll
                    dataLength={this.state.items.length} //This is important field to render the next data
                    next={this.fetchMoreData}
                    hasMore={this.state.hasMore}
                    hasChildren={false}
                    loader={<h4>Loading...</h4>}
                    endMessage={
                        <p style={{textAlign: 'center'}}>
                            <b></b>
                        </p>
                    }
                >
                    {this.blogDataElenco()}
                </InfiniteScroll>
                <PopupboxContainer />
            </Col>
        </Aux>;
    }
}

const mapStateToProps = state => {
    return { 
        router: state.router,
        sezione: state.sezione,
        statico: state.statico,
        blogs: state.blog,
    };
}

export default connect(mapStateToProps, { changePage } )(BlogDetail);