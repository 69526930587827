import { AUTH_LOGIN, AUTH_LOGOUT, IS_AUTH, ERROR_LOGIN } from 'store/actions/types';

const INITIAL_STATE = {
    login: null,
    authenticated: false
};

export default function(state = INITIAL_STATE, action) {
    switch (action.type) {
        case AUTH_LOGIN:
            return { ...state, login: action.payload };
        case AUTH_LOGOUT:
            return { ...state, login: null, authenticated: false };
        case IS_AUTH:
            return { ...state, login: action.payload, authenticated: true };
        case ERROR_LOGIN:
            return { ...state, login: action.payload, authenticated: false };
        default:
            return state;
    }
}