import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Slideshow from 'components/UI/Slideshow/Slideshow';
import { changePage } from 'store/actions';
import 'components/Pagine/403/Forbidden.css';

class Forbidden extends Component {

    componentDidMount() {
        if (this.props.statico.pagine) {

            //console.log(this.props.statico.pagine);
            //console.log('sono in componentDidMount Forbidden');
            
            let paginaObj = Object.keys(this.props.statico.pagine)
                    .filter(key => this.props.statico.pagine[key]["pagina"] === 'Forbidden')
                    .reduce((obj, key) => {
                    return this.props.statico.pagine[key];
                    }, {});
            if (paginaObj) {

                let datiPagina = { ...paginaObj }
                
                this.props.changePage(paginaObj.url, 'Forbidden', 'Forbidden', datiPagina);
            }
        }
    }

    componentDidUpdate() {
        //console.log('sono in componentDidUpdate Forbidden');

        if (!this.props.sezione.nomePagina && this.props.statico.pagine) {
        let paginaObj = Object.keys(this.props.statico.pagine)
                .filter(key => this.props.statico.pagine[key]["pagina"] === 'Forbidden')
                .reduce((obj, key) => {
                return this.props.statico.pagine[key];
                }, {});
            //////console.log(paginaObj);
            if (paginaObj) {
                let datiPagina = { ...paginaObj };
                this.props.changePage(paginaObj.url, 'Forbidden', 'Forbidden', datiPagina);
            }
        }
    }

    onClickBack = () => {
        let sezionePrecedente = this.props.sezione.sezionePagina;
        if(this.props.statico.pagine) {
        let paginaObj = Object.keys(this.props.statico.pagine)
            .filter(key => this.props.statico.pagine[key]["url"] === 'homepage')
            .reduce((obj, key) => {
            return this.props.statico.pagine[key];
            }, {});
        //////console.log(paginaObj);
        if (paginaObj) {
            this.props.changePage(paginaObj.url, 'Homepage', sezionePrecedente, paginaObj);
        } else {
            this.props.changePage('homepage');
        }
        } else {
        this.props.changePage('homepage');
        }
    }

    render() {
        let Aux = props => props.children;

        return (
        <Aux>
            <Slideshow sezioneSlide='forbidden' paginaSlide='forbidden' />
            <Container>
                <Row noGutters className="align-items-center flex-column pt-4 pb-3">
                    <Col sm={12} className="spacer30"></Col>
                    <h1 className="text-danger">403 Forbidden</h1>
                    <h4 className="text-dark">CONFIDENTIAL PAGE!</h4>
                    <p className="text-dark m-3">
                        You are trying to access a reserved page.
                    </p>
                    <Button variant="dark" className="mt-3 mb-2" onClick={this.onClickBack}>Go back to Homepage</Button>
                </Row>
            </Container>
        </Aux>
        );
    }
}

const mapStateToProps = state => {
    return { 
        router: state.router,
        sezione: state.sezione,
        statico: state.statico
    };
}

export default connect(mapStateToProps, { changePage } )(Forbidden);