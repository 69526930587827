import React, { Component } from 'react';
import { connect } from 'react-redux';
import { menuOpen, menuClose } from 'store/actions';
import Menu from 'components/UI/Menu/Menu';
import 'components/UI/Hamburger/Hamburger.css';

class Hamburger extends Component {
  state = {
    isChecked: false
  }

  onMenuHandler = () => {
      if (this.props.menu === "CLOSE" || this.props.menu === '') {
          this.props.menuOpen();
      } else {
          this.props.menuClose();
      }
  };

  toggleChange = () => {
    this.setState({
      isChecked: !this.state.isChecked,
    });
  }

  render() {
    return (
      <div>
        <div className="menuToggle">
            <input type="checkbox" checked={this.state.isChecked} onChange={this.toggleChange} onClick={this.onMenuHandler} />
            <span></span>
            <span></span>
            <span></span>
        </div>
        <Menu onMenuClick={this.toggleChange} />
      </div>
    );  
  }
}

const mapStateToProps = state => {
    return { menu: state.header.menu }
}

export default connect(mapStateToProps, { menuOpen, menuClose })(Hamburger);