import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router'
import { reducer as formReducer } from 'redux-form';
import authReducer from 'store/reducers/authReducer';
import cantineReducer from 'store/reducers/fetchallReducer';
import blogReducer from 'store/reducers/blogReducer';
import sezioneCorrente from 'store/reducers/paginaReducer';
import pagineStatiche from 'store/reducers/staticReducer';
import filtersReducer from 'store/reducers/filtersReducer';
import header from 'store/reducers/menuReducer';

export default (history) => combineReducers({
  router: connectRouter(history),
  header,
  auth: authReducer,
  sezione: sezioneCorrente,
  catalogo: cantineReducer,
  statico: pagineStatiche,
  blog: blogReducer,
  filtri: filtersReducer,
  form: formReducer
});
