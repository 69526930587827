import React, { Component } from 'react';
import { Container, Row } from 'react-bootstrap';
import 'components/UI/Footer/Footer.css';

class Footer extends Component {

    render() {
        return (
          <Container>
              <Row noGutters className="footer">
                  
              </Row>
          </Container>
        )
    }
}

export default Footer;