import { FETCH_STATIC, FETCH_ALL } from 'store/actions/types';

const INITIAL_STATE = {
    pagine: null
};

export default function(state = INITIAL_STATE, action) {
    switch (action.type) {
        case FETCH_STATIC:
            return { ...state, pagine: action.payload };
        case FETCH_ALL:
            return { ...state, pagine: action.payload[0] };
        default:
            return state;
    }
}