import { FETCH_ALL } from 'store/actions/types';

const INITIAL_STATE = {
    blog: null,
    categorie: null,
    tags: null,
};

export default function(state = INITIAL_STATE, action) {
    switch (action.type) {
        case FETCH_ALL:
            return { 
                ...state, 
                blog: action.payload[8][0]['blog'],
                categorie: action.payload[8][0]['categorie'],
                tags: action.payload[8][0]['tags'],
            };
        default:
            return state;
    }
}