import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { PopupboxManager, PopupboxContainer } from 'react-popupbox';
import ReactHtmlParser from 'react-html-parser';
import { fetchAll } from 'store/actions';
import Slider from 'react-slick';
import Spinner from 'components/UI/Spinner/Spinner';
import 'components/UI/Slideshow/Slideshow.css';

class Slideshow extends Component {
	constructor(props) {
		super(props);
		this.currentPage = this.props.paginaSlide;
		this.sezioneSlide = this.props.sezioneSlide;
		this.slideshow = false;
		this.slider = Slider;
		this.prezzoId = this.props.sezione.prezzoId;
		this.changeHandler = this.changeHandler.bind(this);
		this.changeSlider = this.changeSlider.bind(this);
	}

	state = {
		loading: true,
		slideIndex: 0,
		updateCount: 0,
	};

	componentDidMount() {
		// RECUPERO DATI DA API QUANDO MANCANO TUTTI
		if (
			this.props.cantine === null &&
			this.props.tipologie === null &&
			this.props.etichette === null &&
			this.props.vini === null &&
			this.props.formati === null &&
			this.props.confezioni === null &&
			this.props.prezzi === null
		) {
			//////console.log('[Slideshow] recupero fetchAll');
			this.props.fetchAll();
		}

		if (this.prezzoId && this.sezioneSlide === 'dettaglioVino') {
			this.changeHandler(2);
		}
	}

	componentDidUpdate() {}

	shouldComponentUpdate(nextProps, nextState) {
		if (this.props.updateCount !== nextProps.updateCount) {
			return false;
		}
		return true;
	}

	changeHandler(e) {
		////console.log(e);
		this.slider.slickGoTo(e);
	}

	changeSlider() {
		this.setState({
			slideIndex: this.slider.innerSlider.state.currentSlide,
		});
	}

	isEmpty(obj) {
		for (var key in obj) {
			if (obj.hasOwnProperty(key)) return false;
		}
		return true;
	}

	openPopupbox = (immagineZoom) => {
		const content = (
			<Image
				src={`${
					process.env.PUBLIC_URL
				}/images/photogallery/zoom/${immagineZoom}`}
				alt=''
				className='vinoZoom'
			/>
		);
		PopupboxManager.open({
			content,
			config: {
				fadeIn: true,
				fadeInSpeed: 500,
			},
		});
	};

	onClickPdf = () => {
		let pdfEN = this.props.sezione.datiPagina.vinoSelezionato.meta.PDF_EN;
		window.open('https://appeng.terramorettivino.com/pdf/' + pdfEN, '_blank');
	};

	render() {
		const settings = {
			dots: true,
			arrows: false,
			lazyLoad: 'ondemand',
			infinite: false,
			autoplay: false,
			fade: false,
			swipe: true,
			adaptiveHeight: true,
			pauseOnFocus: true,
			autoplaySpeed: 5000,
			speed: 500,
			slidesToShow: 1,
			slidesToScroll: 1,
			afterChange: this.props.afterChange,
			beforeChange: this.props.beforeChange,
		};

		const Aux = (props) => props.children;

		// SLIDESHOW
		let slideshowContainer = '';
		let leftOriented = '';

		console.log('aaaaaaaa' + this.currentPage);

		if (this.currentPage === 'homepage') {
			////////////////////////////////////////////////////////////////
			////////////////////////// HOMEPAGE ////////////////////////////
			////////////////////////////////////////////////////////////////

			let slideObj = null;
			let slideshowGenerico = [];

			//console.log('[Slideshow] creo slideshow Homepage');

			if (this.props.sezione.datiPagina.slideshow) {
				//console.log('[Slideshow] creo slideshow Homepage --> slideshow pronto');

				slideObj = this.props.sezione.datiPagina;
				slideshowGenerico = slideObj['slideshow'].map((slide, key) => {
					return (
						<Row key={key} noGutters>
							<Col className='d-flex flex-md-row flex-column'>
								<Row noGutters>
									<Col
										sm={12}
										lg={6}
										className='p-sm-5 d-flex align-items-center align-content-center justify-content-start'>
										<div className='p-sm-5 p-4 align-self-center'>
											<h1 className='fsemib fsize35'>{slide['Titolo_en']}</h1>
											<div className='p-2' />
											<p className='pSlideshow'>{slide['Testo_en']}</p>
										</div>
									</Col>
									<Col
										sm={12}
										lg={6}
										className='d-flex flex-column justify-content-center align-items-center overflowh'>
										<picture className='immagineSlideshowP'>
											<source
												media='(min-width:1200px)'
												srcSet={`${
													process.env.PUBLIC_URL
												}/images/slideshow/homepage/${slide['Immagine']}.jpg`}
												type='image/jpeg'
											/>
											<source
												media='(min-width:992px)'
												srcSet={`${
													process.env.PUBLIC_URL
												}/images/slideshow/homepage/${
													slide['Immagine']
												}_tablet.jpg`}
												type='image/jpeg'
											/>
											<source
												media='(min-width:768px)'
												srcSet={`${
													process.env.PUBLIC_URL
												}/images/slideshow/homepage/${
													slide['Immagine']
												}_tablet_small.jpg`}
												type='image/jpeg'
											/>
											<source
												media='(min-width:576px)'
												srcSet={`${
													process.env.PUBLIC_URL
												}/images/slideshow/homepage/${slide['Immagine']}.jpg`}
												type='image/jpeg'
											/>
											<Image
												src={`${
													process.env.PUBLIC_URL
												}/images/slideshow/homepage/${slide['Immagine']}.jpg`}
												alt='Terra Moretti'
											/>
										</picture>
									</Col>
								</Row>
							</Col>
						</Row>
					);
				});
			}

			slideshowContainer = (
				<Aux>
					<Image
						fluid
						src={`${process.env.PUBLIC_URL}/images/mappaItalia.png`}
						alt='Terra Moretti'
						className='mappaItalia'
					/>
					<Slider {...settings}>{slideshowGenerico}</Slider>
				</Aux>
			);
		} else if (this.currentPage === 'prezzi') {
			////////////////////////////////////////////////////////////////
			/////////////////////////// PREZZI /////////////////////////////
			////////////////////////////////////////////////////////////////

			let slideObj = null;
			let slideshowPrezzi = [];

			//console.log('[Slideshow] creo slideshow Prezzi');

			if (this.props.sezione.datiPagina.slideshow) {
				//console.log('[Slideshow] creo slideshow Homepage --> slideshow pronto');

				slideObj = this.props.sezione.datiPagina;
				slideshowPrezzi = slideObj['slideshow'].map((slide, key) => {
					return (
						<Row key={key} noGutters>
							<Col className='d-flex flex-md-row flex-column'>
								<Row noGutters>
									<Col
										xs={12}
										className='d-flex flex-column justify-content-center align-items-center overflowh'>
										<picture className='immagineSlideshowP'>
											<source
												media='(min-width:1200px)'
												srcSet={`${
													process.env.PUBLIC_URL
												}/images/slideshow/prezzi/${slide['Immagine']}.jpg`}
												type='image/jpeg'
											/>
											<source
												media='(min-width:992px)'
												srcSet={`${
													process.env.PUBLIC_URL
												}/images/slideshow/prezzi/${
													slide['Immagine']
												}_tablet.jpg`}
												type='image/jpeg'
											/>
											<source
												media='(min-width:768px)'
												srcSet={`${
													process.env.PUBLIC_URL
												}/images/slideshow/prezzi/${
													slide['Immagine']
												}_tablet_small.jpg`}
												type='image/jpeg'
											/>
											<source
												media='(max-width:576px)'
												srcSet={`${
													process.env.PUBLIC_URL
												}/images/slideshow/prezzi/${
													slide['Immagine']
												}_mobile.jpg`}
												type='image/jpeg'
											/>
											<Image
												fluid
												src={`${
													process.env.PUBLIC_URL
												}/images/slideshow/prezzi/${slide['Immagine']}.jpg`}
												alt='Terra Moretti prezzi'
											/>
										</picture>
									</Col>
								</Row>
							</Col>
						</Row>
					);
				});
			}

			slideshowContainer = (
				<Aux>
					<Slider {...settings}>{slideshowPrezzi}</Slider>
				</Aux>
			);
		} else if (
			this.currentPage === 'blog' ||
			this.currentPage === 'blogpost' ||
			this.currentPage === 'forbidden'
		) {
			////////////////////////////////////////////////////////////////
			/////////////////////////// BLOG /////////////////////////////
			////////////////////////////////////////////////////////////////

			let slideObj = null;
			let slideshowBlog = [];

			////console.log('[Slideshow] creo slideshow Blog');

			if (this.props.sezione.datiPagina.slideshow) {
				slideObj = this.props.sezione.datiPagina;
				slideshowBlog = slideObj['slideshow'].map((slide, key) => {
					return (
						<Row key={key} noGutters>
							<Col className='d-flex flex-md-row flex-column'>
								<Row noGutters>
									<Col
										xs={12}
										className='d-flex flex-column justify-content-center align-items-center overflowh'>
										<picture className='immagineSlideshowP'>
											<source
												media='(min-width:1200px)'
												srcSet={`${
													process.env.PUBLIC_URL
												}/images/slideshow/prezzi/${slide['Immagine']}.jpg`}
												type='image/jpeg'
											/>
											<source
												media='(min-width:992px)'
												srcSet={`${
													process.env.PUBLIC_URL
												}/images/slideshow/prezzi/${
													slide['Immagine']
												}_tablet.jpg`}
												type='image/jpeg'
											/>
											<source
												media='(min-width:768px)'
												srcSet={`${
													process.env.PUBLIC_URL
												}/images/slideshow/prezzi/${
													slide['Immagine']
												}_tablet_small.jpg`}
												type='image/jpeg'
											/>
											<source
												media='(max-width:576px)'
												srcSet={`${
													process.env.PUBLIC_URL
												}/images/slideshow/prezzi/${
													slide['Immagine']
												}_mobile.jpg`}
												type='image/jpeg'
											/>
											<Image
												fluid
												src={`${
													process.env.PUBLIC_URL
												}/images/slideshow/prezzi/${slide['Immagine']}.jpg`}
												alt='Terra Moretti prezzi'
											/>
										</picture>
									</Col>
								</Row>
							</Col>
						</Row>
					);
				});
			}

			slideshowContainer = (
				<Aux>
					<Slider {...settings}>{slideshowBlog}</Slider>
				</Aux>
			);
		} else if (!this.isEmpty(this.props.sezione.datiPagina.vinoSelezionato)) {
			////////////////////////////////////////////////////////////////
			//////////////////////// SCHEDA VINO ///////////////////////////
			////////////////////////////////////////////////////////////////

			let slideshowVino = [];
			let tabellaTecnica = [];
			let formati = [];
			let prezzi = [];
			let photogallery = [];
			let logoCantina = '';
			let bottigliaVino = '';
			let nomeVino = '';
			let descrizioneVino = '';
			let bgCantina = '';
			let tipologiaVino = '';
			let cantinaObj = null;
			let vinoObj = null;
			if (this.props.sezione.datiPagina) {
				cantinaObj = this.props.sezione.datiPagina;
				logoCantina = cantinaObj['logo'];
				bgCantina = cantinaObj['slideshow'][0]['Immagine'];
			}

			if (this.props.sezione.datiPagina.vinoSelezionato) {
				vinoObj = this.props.sezione.datiPagina['vinoSelezionato'];
				bottigliaVino = vinoObj.meta['ImmagineBottiglia'];
				nomeVino = vinoObj.Vino;
				descrizioneVino = ReactHtmlParser(vinoObj.meta['Descrizione_EN']);
				////console.log(bottigliaVino);

				// PDF?
				let pdf_doc = '';
				if (this.props.sezione.datiPagina.vinoSelezionato.meta.PDF_EN) {
					pdf_doc = (
						<div className='downloadPdf'>
							<span className='fsize12 fsemib'>download pdf</span>
							<Image
								src={`${process.env.PUBLIC_URL}/images/download_pdf.png`}
								alt='Scarica pdf vino'
								onClick={this.onClickPdf}
							/>
						</div>
					);
				}

				if (this.props.tipologie) {
					let tipologiaObj = Object.keys(this.props.tipologie)
						.filter(
							(key) =>
								this.props.tipologie[key]['id'] === vinoObj.meta.Tipologia
						)
						.reduce((obj, key) => {
							return this.props.tipologie[key];
						}, {});
					////console.log(tipologiaObj);
					if (tipologiaObj) {
						tipologiaVino = tipologiaObj.tipologia_en;
					}
				}

				// tabella tecnica
				if (this.props.sezione.datiPagina.vinoSelezionato.meta) {
					let vinoMetaObj = this.props.sezione.datiPagina.vinoSelezionato.meta;
					let tabellaTecnicaObj = Object.keys(vinoMetaObj)
						.filter(
							(key) =>
								key.indexOf('_EN') !== -1 &&
								key.indexOf('Descrizione') === -1 &&
								key.indexOf('PDF') === -1
						)
						.reduce((obj, key) => {
							////console.log(key+' '+vinoMetaObj[key]);
							let keyT = key.replace('_EN', '');
							obj[keyT] = vinoMetaObj[key];
							return obj;
						}, {});
					tabellaTecnica = Object.keys(tabellaTecnicaObj).map(
						(key, counter) => {
							// recupero etichetta decodificata
							let etichetteObj = this.props.etichette;
							////console.log(etichetteObj);
							let etichettaDecodificata = Object.keys(etichetteObj)
								.filter(
									(key2) => etichetteObj[key2]['etichetta_en'] === `${key}_EN`
								)
								.reduce((obj, key3) => {
									return etichetteObj[key3]['codifica_en'];
								}, {});
							let etichetta = '';
							etichettaDecodificata
								? (etichetta = etichettaDecodificata)
								: (etichetta = '');
							return (
								<Aux key={counter}>
									<Row>
										<Col
											xs={5}
											className='keyTabella fsize12 fsemib d-flex justify-content-end align-items-center'>
											{etichetta}
										</Col>
										<Col
											xs={7}
											className='valueTabella fsize12 d-flex align-items-center'>
											{tabellaTecnicaObj[key]}
										</Col>
									</Row>
								</Aux>
							);
						}
					);
				}

				// elenco formati bottiglie
				if (this.props.sezione.datiPagina.vinoSelezionato.formati) {
					let formatiObj = this.props.sezione.datiPagina.vinoSelezionato
						.formati;
					formati = Object.keys(formatiObj).map((key, counter) => {
						////console.log(formatiObj[key]);
						let bottiglia_icona = `${formatiObj[key]}_${
							this.props.sezione.datiPagina.url
						}.png`;
						let formato_txt = `${formatiObj[key]}ml`;
						return (
							<Col
								xs={2}
								key={counter}
								className='formatoBottiglia fsize15 fsemib d-flex flex-column justify-content-end align-items-center'>
								<div className='text-center'>
									<Image
										src={`${
											process.env.PUBLIC_URL
										}/images/cantine/icone_bottiglie/${bottiglia_icona}`}
										alt={`${formato_txt}`}
										className='align-self-end'
									/>
									<br />
									<span>{`${formato_txt}`}</span>
								</div>
							</Col>
						);
					});
				}

				// tabella prezzi
				if (this.props.sezione.datiPagina.vinoSelezionato.prezzi) {
					let prezziObj = this.props.sezione.datiPagina.vinoSelezionato.prezzi;
					prezzi = Object.keys(prezziObj).map((key, counter) => {
						////console.log(prezziObj[key]);
						let IDPrezzo = `${prezziObj[key]['ID']}`;
						let annata = `${prezziObj[key]['Annata']}`;
						let formato_desc = `${prezziObj[key]['FormatoDesc']}`;
						let confezionamento = `${prezziObj[key]['Confezionamento']}`;
						/* let prezzoFix = prezziObj[key]["Prezzo"].replace('.',','); */
						/* let prezzo = `€${prezzoFix}`; */
						let disponibilita = `${prezziObj[key]['Disponibilita']}`;
						let prezzoSelected = '';
						if (this.prezzoId === IDPrezzo) {
							prezzoSelected = 'selected';
						} else {
							prezzoSelected = '';
						}
						return (
							<Aux key={counter}>
								<Row noGutters className={`rowPrezzi ${prezzoSelected}`}>
									<Col
										xs={2}
										className='d-flex justify-content-center align-items-center'>
										{annata}
									</Col>
									<Col
										xs={2}
										className='d-flex justify-content-center align-items-center'>
										{formato_desc}
									</Col>
									<Col
										xs={4}
										className='d-flex justify-content-center align-items-center'>
										{confezionamento}
									</Col>
									<Col
										xs={2}
										className='d-flex justify-content-center align-items-center'>
										***
									</Col>
									<Col
										xs={2}
										className='d-flex justify-content-center align-items-center'>
										{disponibilita}
									</Col>
								</Row>
							</Aux>
						);
					});
				}

				// photogallery
				if (this.props.sezione.datiPagina.vinoSelezionato.photogallery) {
					let galleryObj = this.props.sezione.datiPagina.vinoSelezionato
						.photogallery;
					photogallery = Object.keys(galleryObj).map((key, counter) => {
						////console.log(galleryObj[key]);
						let immagine = `${galleryObj[key]}`;
						return (
							<Aux key={counter}>
								<Col
									xs={4}
									className='d-flex justify-content-center align-items-center'>
									<Image
										fluid
										src={`${
											process.env.PUBLIC_URL
										}/images/photogallery/thumbs/${immagine}`}
										alt=''
										className='vinoThumb'
										onClick={() => this.openPopupbox(immagine)}
									/>
								</Col>
							</Aux>
						);
					});
				}

				leftOriented = 'leftOriented';

				// DESCRIZIONE VINO
				slideshowVino[0] = (
					<Row key='0' noGutters>
						<Col className='d-flex flex-md-row flex-column'>
							<Row noGutters>
								<Col
									sm={12}
									lg={{ span: 3, offset: 1 }}
									className='d-flex justify-content-center align-items-center overflowh imgSlideCantina'>
									<picture className='immagineSlideshowP'>
										<source
											media='(min-width:1200px)'
											srcSet={`${
												process.env.PUBLIC_URL
											}/images/slideshow/cantine/${bgCantina}.jpg`}
											type='image/jpeg'
										/>
										<source
											media='(min-width:768px)'
											srcSet={`${
												process.env.PUBLIC_URL
											}/images/slideshow/cantine/${bgCantina}_tablet.jpg`}
											type='image/jpeg'
										/>
										<source
											media='(min-width:576px)'
											srcSet={`${
												process.env.PUBLIC_URL
											}/images/slideshow/cantine/${bgCantina}.jpg`}
											type='image/jpeg'
										/>
										<Image
											src={`${
												process.env.PUBLIC_URL
											}/images/slideshow/cantine/${bgCantina}.jpg`}
											alt='Terra Moretti'
										/>
									</picture>
								</Col>
								<Col
									sm={12}
									lg={8}
									className='p-sm-5 d-lg-flex d-block flex-sm-column flex-lg-row flex-column align-items-center justify-content-start'>
									<Col
										sm={12}
										lg={1}
										className='scrittaSlideVinoCont align-items-center'>
										<span className='fsize25 scrittaSlideVino'>
											<span>informations</span>
										</span>
									</Col>
									<Col sm={12} lg={11} className='p-0'>
										{pdf_doc}
										<div className='pl-5'>
											<h2 className='fsemib fsize20'>{tipologiaVino}</h2>
											<h1 className='fsemib fsize35'>{nomeVino}</h1>
											<div className='p-2' />
											<p className='pSlideshow fsize14'>{descrizioneVino}</p>
										</div>
									</Col>
								</Col>
							</Row>
						</Col>
					</Row>
				);
				// SCHEDA TECNICA (oscurata 18/03/2022)
				/* slideshowVino[1] =
              <Row key='1' noGutters>
                    <Col className='d-flex flex-md-row flex-column'>
                      <Row noGutters>
                        <Col sm={12} lg={{ span: 3, offset: 1 }} className="d-flex flex-column justify-content-center align-items-center overflowh imgSlideCantina">
                          <picture className="immagineSlideshowP">
                            <source media="(min-width:1200px)" srcSet={`${process.env.PUBLIC_URL}/images/slideshow/cantine/${bgCantina}.jpg`} type="image/jpeg" />
                            <source media="(min-width:768px)" srcSet={`${process.env.PUBLIC_URL}/images/slideshow/cantine/${bgCantina}_tablet.jpg`}  type="image/jpeg" />
                            <source media="(min-width:576px)" srcSet={`${process.env.PUBLIC_URL}/images/slideshow/cantine/${bgCantina}.jpg`}  type="image/jpeg" />
                            <Image
                              src={`${process.env.PUBLIC_URL}/images/slideshow/cantine/${bgCantina}.jpg`} 
                              alt='Terra Moretti'
                            />
                          </picture>
                        </Col>
                        <Col
                          sm={12}
                          lg={8}
                          className='p-sm-5 d-lg-flex d-block flex-sm-column flex-lg-row flex-column align-items-center justify-content-start'>
                            <Col sm={12} lg={1} className="scrittaSlideVinoCont align-items-center">
                              <span className="fsize25 scrittaSlideVino"><span>tasting card</span></span>
                            </Col>
                            <Col sm={12} lg={11} className="p-0">
                              {pdf_doc}
                              <div className='pl-5'>
                                <h2 className='fsemib fsize20'>
                                  {tipologiaVino}
                                </h2>
                                <h1 className='fsemib fsize35'>
                                  {nomeVino}
                                </h1>
                                <div className="p-2"></div>
                                <div className="pSlideshow fsize14">
                                  {tabellaTecnica}
                                </div>
                              </div>
                            </Col>
                        </Col>
                      </Row>
                    </Col>
                </Row>; */
				// PREZZI FORMATI (oscurata 18/03/2022)
				/*  slideshowVino[2] =
            <Row key='2' noGutters>
                  <Col className='d-flex flex-md-row flex-column'>
                    <Row noGutters>
                      <Col sm={12} lg={{ span: 3, offset: 1 }} className="d-flex flex-column justify-content-center align-items-center overflowh imgSlideCantina">
                        <picture className="immagineSlideshowP">
                          <source media="(min-width:1200px)" srcSet={`${process.env.PUBLIC_URL}/images/slideshow/cantine/${bgCantina}.jpg`} type="image/jpeg" />
                          <source media="(min-width:768px)" srcSet={`${process.env.PUBLIC_URL}/images/slideshow/cantine/${bgCantina}_tablet.jpg`}  type="image/jpeg" />
                          <source media="(min-width:576px)" srcSet={`${process.env.PUBLIC_URL}/images/slideshow/cantine/${bgCantina}.jpg`}  type="image/jpeg" />
                          <Image
                            src={`${process.env.PUBLIC_URL}/images/slideshow/cantine/${bgCantina}.jpg`} 
                            alt='Terra Moretti'
                          />
                        </picture>
                      </Col>
                      <Col
                        sm={12}
                        lg={8}
                        className='p-sm-5 d-lg-flex d-block flex-sm-column flex-lg-row flex-column align-items-center justify-content-start'>
                          <Col sm={12} lg={1} className="scrittaSlideVinoCont align-items-center">
                            <span className="fsize25 scrittaSlideVino"><span>formats & prices</span></span>
                          </Col>
                          <Col sm={12} lg={11} className="p-0">
                            {pdf_doc}
                            <div className='pl-5'>
                              <h2 className='fsemib fsize20'>
                                {tipologiaVino}
                              </h2>
                              <h1 className='fsemib fsize35'>
                                {nomeVino}
                              </h1>
                              <div className="pSlideshow fsize12">
                                <Row noGutters>
                                  {formati}
                                </Row>
                                <div className="p-2"></div>
                                <Row noGutters>
                                  <Col xs={2} className="titoloPrezzo fsemib d-flex justify-content-center align-items-center">
                                    Year
                                  </Col>
                                  <Col xs={2} className="titoloPrezzo fsemib d-flex justify-content-center align-items-center">
                                    Formats
                                  </Col>
                                  <Col xs={4} className="titoloPrezzo fsemib d-flex justify-content-center align-items-center">
                                    Package
                                  </Col>
                                  <Col xs={2} className="titoloPrezzo fsemib d-flex justify-content-center align-items-center">
                                    Price
                                  </Col>
                                  <Col xs={2} className="titoloPrezzo fsemib d-flex justify-content-center align-items-center">
                                    Availab.
                                  </Col>
                                </Row>
                                {prezzi}
                              </div>
                            </div>
                          </Col>
                      </Col>
                    </Row>
                  </Col>
              </Row>; */
				// PHOTOGALLERY (oscurata 18/03/2022)
				/* if (this.props.sezione.datiPagina.vinoSelezionato.photogallery) {
					slideshowVino[3] = (
						<Row key='3' noGutters>
							<Col className='d-flex flex-md-row flex-column'>
								<Row noGutters>
									<Col
										sm={12}
										lg={{ span: 3, offset: 1 }}
										className='d-flex flex-column justify-content-center align-items-center overflowh imgSlideCantina'>
										<picture className='immagineSlideshowP'>
											<source
												media='(min-width:1200px)'
												srcSet={`${
													process.env.PUBLIC_URL
												}/images/slideshow/cantine/${bgCantina}.jpg`}
												type='image/jpeg'
											/>
											<source
												media='(min-width:768px)'
												srcSet={`${
													process.env.PUBLIC_URL
												}/images/slideshow/cantine/${bgCantina}_tablet.jpg`}
												type='image/jpeg'
											/>
											<source
												media='(min-width:576px)'
												srcSet={`${
													process.env.PUBLIC_URL
												}/images/slideshow/cantine/${bgCantina}.jpg`}
												type='image/jpeg'
											/>
											<Image
												src={`${
													process.env.PUBLIC_URL
												}/images/slideshow/cantine/${bgCantina}.jpg`}
												alt='Terra Moretti'
											/>
										</picture>
									</Col>
									<Col
										sm={12}
										lg={8}
										className='p-sm-5 d-lg-flex d-block flex-sm-column flex-lg-row flex-column align-items-center justify-content-start'>
										<Col
											sm={12}
											lg={1}
											className='scrittaSlideVinoCont align-items-center'>
											<span className='fsize25 scrittaSlideVino'>
												<span>photogallery</span>
											</span>
										</Col>
										<Col sm={12} lg={11} className='p-0'>
											{pdf_doc}
											<div className='pl-5'>
												<h2 className='fsemib fsize20'>{tipologiaVino}</h2>
												<h1 className='fsemib fsize35'>{nomeVino}</h1>
												<div className='p-2' />
												<div className='pSlideshow'>
													<Row noGutters className='photogallery_container'>
														{photogallery}
													</Row>
												</div>
											</div>
										</Col>
									</Col>
								</Row>
							</Col>
						</Row>
					);
				} */
			}

			// fix logo cantina e bottiglia cantina quando non sono ancora valorizzate --> evito chiamata vuota http 403 a /images/cantine/loghi/ e /images/cantine/bottiglie/
			let imageLogo = '';
			let imageBottigliaVino = '';
			if (logoCantina) {
				imageLogo = (
					<Image
						src={`${
							process.env.PUBLIC_URL
						}/images/cantine/loghi/${logoCantina}`}
						alt='Logo cantina'
						className='logoCantinaInterno'
					/>
				);
			}
			if (bottigliaVino) {
				imageBottigliaVino = (
					<Image
						src={`${
							process.env.PUBLIC_URL
						}/images/bottiglie/${bottigliaVino}.png`}
						alt={`${nomeVino}`}
						className='bottigliaVino'
					/>
				);
			}

			slideshowContainer = (
				<Aux>
					{imageLogo}
					{imageBottigliaVino}
					<Slider
						ref={(slider) => (this.slider = slider)}
						{...settings}
						className='slideshowVino'>
						{slideshowVino}
					</Slider>
				</Aux>
			);
		} else {
			// SLIDESHOW CANTINA
			let slideshowCantina = [];
			let cantinaObj = null;
			let logoCantina = '';
			let bottigliaCantina = '';
			if (this.props.sezione.datiPagina.slideshow) {
				cantinaObj = this.props.sezione.datiPagina;
				let nomeCantina = '';
				if (cantinaObj['nomealternativo']) {
					nomeCantina = cantinaObj['nomealternativo'];
				} else {
					nomeCantina = cantinaObj['cantina'];
				}
				logoCantina = cantinaObj['logo'];
				bottigliaCantina = cantinaObj['bottiglia'];

				slideshowCantina = cantinaObj['slideshow'].map((slide, key) => {
					return (
						<Row key={key} noGutters>
							<Col className='d-flex flex-md-row flex-column'>
								<Row noGutters>
									<Col
										sm={12}
										lg={6}
										className='d-flex flex-column justify-content-center align-items-center overflowh imgSlideCantina'>
										<picture className='immagineSlideshowP'>
											<source
												media='(min-width:1200px)'
												srcSet={`${
													process.env.PUBLIC_URL
												}/images/slideshow/cantine/${slide['Immagine']}.jpg`}
												type='image/jpeg'
											/>
											<source
												media='(min-width:768px)'
												srcSet={`${
													process.env.PUBLIC_URL
												}/images/slideshow/cantine/${
													slide['Immagine']
												}_tablet.jpg`}
												type='image/jpeg'
											/>
											<source
												media='(min-width:576px)'
												srcSet={`${
													process.env.PUBLIC_URL
												}/images/slideshow/cantine/${slide['Immagine']}.jpg`}
												type='image/jpeg'
											/>
											<Image
												src={`${
													process.env.PUBLIC_URL
												}/images/slideshow/cantine/${slide['Immagine']}.jpg`}
												alt='Terra Moretti'
											/>
										</picture>
									</Col>
									<Col
										sm={12}
										lg={6}
										className='p-sm-5 d-flex align-items-center justify-content-start'>
										<div className='pl-5'>
											<h1 className='fsemib fsize35'>{nomeCantina}</h1>
											<h3 className='fsemib fsize14'>{slide['Titolo_en']}</h3>
											<div className='p-2' />
											<p className='pSlideshow fsize14'>{slide['Testo_en']}</p>
										</div>
									</Col>
								</Row>
							</Col>
						</Row>
					);
				});
			}

			leftOriented = 'leftOriented';

			// fix logo cantina e bottiglia cantina quando non sono ancora valorizzate --> evito chiamata vuota http 403 a /images/cantine/loghi/ e /images/cantine/bottiglie/
			let imageLogo = '';
			let imageBottiglia = '';
			if (logoCantina) {
				imageLogo = (
					<Image
						src={`${
							process.env.PUBLIC_URL
						}/images/cantine/loghi/${logoCantina}`}
						alt='Logo cantina'
						className='logoCantinaInterno'
					/>
				);
			}
			if (bottigliaCantina) {
				imageBottiglia = (
					<Image
						src={`${
							process.env.PUBLIC_URL
						}/images/cantine/bottiglie/${bottigliaCantina}`}
						alt='Bottiglia cantina'
						className='bottigliaCantina'
					/>
				);
			}

			slideshowContainer = (
				<Aux>
					{imageLogo}
					{/* {imageBottiglia} */}
					<Slider {...settings} className='slideshowCantina'>
						{slideshowCantina}
					</Slider>
				</Aux>
			);
		}

		return (
			<Container>
				<Row noGutters>
					<Col className='d-flex bg-grigio overflowh containerSlideshowCantine'>
						<Spinner
							loading={this.state.loading}
							classStyles={`spinnerSlideshow ${leftOriented}`}
						/>
						{slideshowContainer}
					</Col>
					<PopupboxContainer />
				</Row>
			</Container>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		router: state.router,
		sezione: state.sezione,
		cantine: state.catalogo.cantine,
		tipologie: state.catalogo.tipologie,
		etichette: state.catalogo.etichette,
		vini: state.catalogo.vini,
		formati: state.catalogo.formati,
		confezioni: state.catalogo.confezioni,
		prezzi: state.catalogo.prezzi,
	};
};

export default connect(
	mapStateToProps,
	{ fetchAll }
)(Slideshow);
