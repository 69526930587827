import React from 'react';
import { ClipLoader } from 'react-spinners';
import 'components/UI/Spinner/Spinner.css';

const override = `
    display: block;
    margin: 0 auto;
    border-color: red;
`;

const Spinner = props => {
  //console.log(props.classStyles);
  return (
    <div className={`sweet-loading align-self-center ${props.classStyles}`}>
        <ClipLoader
        css={override}
        sizeUnit={"px"}
        size={40}
        color={'#1d5c39'}
        loading={props.loading}
        />
    </div>
  );
}

export default Spinner;