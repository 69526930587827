import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import Slideshow from 'components/UI/Slideshow/Slideshow';
import BlogDetail from 'components/Pagine/Blog/BlogDetail/BlogDetail';
import BlogFilters from 'components/Pagine/Blog/BlogFilters/BlogFilters';
import { changePage } from 'store/actions';
import 'components/Pagine/Blog/BlogPost.css';

class BlogPost extends Component {

    render() {
        let Aux = props => props.children;

        return (
        <Aux>
            <Slideshow sezioneSlide='blogpost' paginaSlide='blogpost' />
            <Container>
                <Row noGutters>
                    <Col sm={12} className="spacer30"></Col>
                    <BlogFilters />
                    <BlogDetail />
                </Row>
            </Container>
        </Aux>
        );
    }
}

const mapStateToProps = state => {
    return { 
        router: state.router,
        sezione: state.sezione,
        statico: state.statico,
        blogs: state.blog,
    };
}

export default connect(mapStateToProps, { changePage } )(BlogPost);