import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Row } from 'react-bootstrap';
import { menuClose, changePage, authLogout, changeFilter } from 'store/actions';
import Modal from 'react-responsive-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'components/UI/Menu/Menu.css';

class Menu extends Component {
	state = {
		open: false,
		username: null,
	};

	componentDidUpdate() {
		if (this.props.menu === 'OPEN' && this.state.open === false) {
			this.onOpenModal();
		} else if (this.props.menu === 'CLOSE' && this.state.open === true) {
			this.onCloseModal();
		}

		if (this.props.auth.login && this.state.username === null) {
			this.setState({ username: this.props.auth.login.username });
		}
	}

	onOpenModal = () => {
		this.setState({ open: true });
	};

	onCloseModal = () => {
		this.setState({ open: false });
	};

	closeMenuAndRedirect = (pagina, sezionePagina) => {
		this.props.onMenuClick(); // chiudo l'hamburger senza cliccarlo direttamente
		this.props.menuClose(); // chiudo il modal
		this.props.changeFilter(null); // resetto i filtri

		// se url diverso da pagina destinazione chiamo action creator changePage, altrimenti chiudo solo menu
		if (pagina !== this.props.sezione.nomePagina) {
			let sezionePrecedente = this.props.sezione.sezionePrecedente;

			if (this.props.cantine) {
				let cantinaObj = Object.keys(this.props.cantine)
					.filter((key) => this.props.cantine[key]['url'] === pagina)
					.reduce((obj, key) => {
						return this.props.cantine[key];
					}, {});
				////console.log(cantinaObj);
				// link a pagina cantina?
				if (cantinaObj.url) {
					this.props.changePage(
						`${cantinaObj.url}`,
						sezionePagina,
						sezionePrecedente,
						cantinaObj
					); // vado alla pagina della cantina
				} else {
					if (this.props.statico.pagine) {
						let paginaObj = Object.keys(this.props.statico.pagine)
							.filter((key) => this.props.statico.pagine[key]['url'] === pagina)
							.reduce((obj, key) => {
								return this.props.statico.pagine[key];
							}, {});
						/* //console.log(paginaObj); */
						if (Object.entries(paginaObj).length !== 0) {
							// FIX PER SEZIONE BLOG
							if (sezionePagina === 'Blog') {
								let elencoBlog = this.props.blogs.blog;
								paginaObj = {
									...paginaObj,
									elencoBlog: elencoBlog,
									categoriaSelezionata: null,
									categoriaColore: null,
									tagSelezionato: null,
								};
							}

							this.props.changePage(
								paginaObj.url,
								sezionePagina,
								sezionePrecedente,
								paginaObj,
								null,
								true
							); // passo ad altra pagina
						} else {
							/* //console.log('aaaa'); */
							this.props.changePage(
								pagina,
								sezionePagina,
								sezionePrecedente,
								null
							);
						}
					} else {
						/* //console.log('vvvv'); */
						this.props.changePage(
							pagina,
							sezionePagina,
							sezionePrecedente,
							null
						);
					}
				}
			}
		} else {
			//console.log('stessa pagina');
		}
	};

	onLogout = () => {
		this.props.onMenuClick(); // chiudo l'hamburger senza cliccarlo direttamente
		this.props.menuClose(); // chiudo il modal
		this.props.changeFilter(null); // resetto i filtri
		this.props.authLogout(); // effettuo il logout
	};

	sort = (prop, arr) => {
		prop = prop.split('.');
		const len = prop.length;

		arr.sort(function(a, b) {
			let i = 0;
			while (i < len) {
				a = a[prop[i]];
				b = b[prop[i]];
				i++;
			}
			if (a < b) {
				return -1;
			} else if (a > b) {
				return 1;
			} else {
				return 0;
			}
		});
		return arr;
	};

	onRenderMenu = () => {
		let cantineLi = '';
		if (this.props.cantine) {
			const newArray = [...this.props.cantine];
			const newArrayCantine = this.sort('cantina', newArray);
			cantineLi = newArrayCantine.map(
				({ id, cantina, nomealternativo, colore, url }) => {
					let nomecantina = '';
					if (nomealternativo) {
						nomecantina = nomealternativo;
					} else {
						nomecantina = cantina;
					}
					return (
						<li
							key={id}
							className='cantinaLi'
							style={{ borderBottom: `5px solid ${colore}` }}
							onClick={() => this.closeMenuAndRedirect(`${url}`, 'Cantina')}>
							{nomecantina}
						</li>
					);
				}
			);
		}

		return (
			<Container>
				<Row noGutters className='menuLangs'>
					<h3
						className='fsize22'
						onClick={() =>
							(window.location = 'https://app.terramorettivino.com')
						}>
						IT
					</h3>
					<span>|</span>
					<h3 className='selected fsize22'>EN</h3>
				</Row>
				<Row noGutters>
					<ul className='listMenu fsize25'>
						<li
							onClick={() => this.closeMenuAndRedirect('homepage', 'Homepage')}>
							HOME
						</li>
						<li className='elencoCantine'>THE WINERIES</li>
						{cantineLi}
						{/*
						<li className='elencoCantine'>PRICES</li>
						<li
							onClick={() => this.closeMenuAndRedirect('blog', 'Blog')}
							className='blogIcon'>
							<FontAwesomeIcon icon={['far', 'blog']} /> BLOG
						</li>
						*/}
						<li />
						<li className='logoutMsg fsize20'>
							You are connected as <b>{this.state.username}</b>
						</li>
						<li onClick={this.onLogout} className='logout fsize20'>
							Logout
						</li>
						<li className='versionepwa fsize18'>v.2.2.0 - 18/03/2022</li>
					</ul>
				</Row>
			</Container>
		);
	};

	render() {
		const { open } = this.state;
		return (
			<div>
				<Modal
					open={open}
					onClose={this.onCloseModal}
					center
					classNames={{
						overlay: 'overlayCustom',
						modal: 'modalCustom',
					}}
					closeOnOverlayClick={false}
					showCloseIcon={false}>
					{this.onRenderMenu()}
				</Modal>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		auth: state.auth,
		sezione: state.sezione,
		menu: state.header.menu,
		cantine: state.catalogo.cantine,
		statico: state.statico,
		blogs: state.blog,
	};
};

export default connect(
	mapStateToProps,
	{ menuClose, changePage, authLogout, changeFilter }
)(Menu);
