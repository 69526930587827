import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import TrackVisibility from 'react-on-screen';
import BoxVino from 'components/Pagine/Cantina/ElencoVini/BoxVino/BoxVino';
import 'components/Pagine/Cantina/ElencoVini/ElencoVini.css';

class ElencoVini extends Component {
  componentDidMount() {
    //console.log('[ElencoVini] componentDidMount:: caricato');
  }

  componentDidUpdate() {
    //console.log('[ElencoVini] componentDidUpdate:: aggiornato');
  }

  componentWillUnmount() {
    //console.log('[ElencoVini] componentWillUnmount:: uscendo');
  }

  sort = (prop, arr) => {
    prop = prop.split('.');
    const len = prop.length;

    arr.sort(function (a, b) {
        let i = 0;
        while( i < len ) { a = a[prop[i]]; b = b[prop[i]]; i++; }
        if (a < b) {
            return -1;
        } else if (a > b) {
            return 1;
        } else {
            return 0;
        }
    });
    return arr;
  };

  onRenderElencoVini = () => {
    // POPOLO ELENCO VINI
    let vinoObj = null;
    let filtroCantina = '';
    /* let filtroVino = '';
    let filtroTipologia = '';
    let filtroFormato = '';
    let filtroConfezione = ''; */

    if(this.props.filtri) {
      //console.log(this.props.filtri);
      if(this.props.filtri.cantina !== undefined) {
        filtroCantina = this.props.filtri.cantina.toLocaleLowerCase();
        //console.log(filtroCantina);
      }
      if(this.props.filtri.vino !== undefined) {
        //filtroVino = this.props.filtri.vino.toLocaleLowerCase();
        //console.log(filtroVino);
      }
      if(this.props.filtri.tipologia !== undefined) {
        //filtroTipologia = this.props.filtri.tipologia.toLocaleLowerCase();
        //console.log(filtroTipologia);
      }
      if(this.props.filtri.formato !== undefined) {
        //filtroFormato = this.props.filtri.formato.toLocaleLowerCase();
        //console.log(filtroFormato);
      }
      if(this.props.filtri.confezione !== undefined) {
        //filtroConfezione = this.props.filtri.confezione.toLocaleLowerCase();
        //console.log(filtroConfezione);
      }
    }

    if(this.props.sezione.datiPagina.vini) {
      //console.log('caricato elenco vini');
  
      let newArray = '';
      let newArraySorted = '';

      if (filtroCantina && this.props.vini) {
        vinoObj = this.props.vini;
        newArray = [...vinoObj];
        newArraySorted = this.sort('Vino', newArray);
      } else {
        vinoObj = this.props.sezione.datiPagina;
        newArraySorted = vinoObj["vini"];
      }
      
      return newArraySorted.map((vino, key) => {

        /* let formati = `${vino.formati}`;
        //console.log(typeof(formati));
        let formatInt = formati.map(x => { 
          return parseInt(x, 10); 
        }); */
        /*let tipologia = `${vino[key]["Tipologia_it"]}`;
        let confezionamento = `${vino[key]["confezionamento"]}`; */

        
        /* //console.log('-------------');
        //console.log(vino);
        //console.log(vino.formati);
        //console.log('filtroFormato '+filtroFormato);
        //console.log(formatInt.includes(filtroFormato));
        //console.log('-------------'); */

        // FILTRI
        if (filtroCantina && filtroCantina !== vino.Cantina.toLocaleLowerCase()) { return null }
        /* if (filtroFormato && formati.indexOf(filtroFormato) < 0) { return null } */
        /* if (filtroTipologia && filtroTipologia !== tipologia.toLocaleLowerCase()) { return null }
        
        if (filtroConfezione && filtroConfezione !== confezionamento.toLocaleLowerCase()) { return null } */

        let ComponentToTrack = ({ isVisible }) => {
            let style = {
                opacity: isVisible ? '1' : '0'
            };
            return <BoxVino vino={vino.ID} style={style} />;
        }

        return <Col xs={6} md={4} lg={3} key={key} className="p-0">
            <TrackVisibility partialVisibility className="boxVino">
              <ComponentToTrack />
            </TrackVisibility>
          </Col>
      });
    }
  }

  render() {

    let elencoVini = this.props.sezione.datiPagina.vini ? this.onRenderElencoVini() : '';

    return (
      <Container>
        <Row>{elencoVini}</Row>
      </Container>
    )
  }
}

const mapStateToProps = state => {
  return {
    sezione: state.sezione,
    cantine: state.catalogo.cantine,
    tipologie: state.catalogo.tipologie,
    vini: state.catalogo.vini,
    filtri: state.filtri.selezionati 
  }
}

export default connect(mapStateToProps)(ElencoVini);