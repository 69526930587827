import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import { fetchCantine, fetchStatic, fetchTipologie, fetchEtichette, changePage } from 'store/actions';
import Slideshow from 'components/UI/Slideshow/Slideshow';
import Filtri from 'components/UI/Filtri/Filtri';
import ElencoVini from 'components/Pagine/Cantina/ElencoVini/ElencoVini';
import 'components/Pagine/Cantina/Cantina.css';

class Cantina extends Component {
  state = {
    pageLoaded: false
  }

  componentDidMount() {
    //console.log('[Cantina] componentDidMount');
    //console.log(this.props.router.action);

    let sezioneCorrente = this.props.sezione.sezionePagina;
    let sezionePrecedente = this.props.sezione.sezionePrecedente;

    if(this.props.cantine) {
      //console.log('[Cantina] componentDidMount:: aggiorno cambio pagina');
      let currentPath = this.props.location.pathname.split('/');
      //console.log(currentPath);
      let cantinaObj = Object.keys(this.props.cantine)
            .filter(key => this.props.cantine[key]["url"] === currentPath[1])
            .reduce((obj, key) => {
              return this.props.cantine[key];
            }, {});
      this.props.changePage(`${cantinaObj.url}`, sezioneCorrente, sezionePrecedente, cantinaObj); // aggiorno pagina corrente
    }
  }

  componentDidUpdate() {
    let sezioneCorrente = this.props.sezione.sezionePagina;
    if (!sezioneCorrente) sezioneCorrente = 'Cantina';
    let sezionePrecedente = this.props.sezione.sezionePrecedente;
    if (!sezionePrecedente) sezionePrecedente = 'Cantina';


    //console.log('[Cantina] componentDidUpdate');
    //console.log(this.props.router.action);

    if(!this.props.sezione.nomePagina && this.props.cantine) {
      //console.log('[Cantina] componentDidUpdate:: aggiorno cambio pagina');
      let currentPath = this.props.location.pathname.split('/');
      //console.log(currentPath);
      let cantinaObj = Object.keys(this.props.cantine)
            .filter(key => this.props.cantine[key]["url"] === currentPath[1])
            .reduce((obj, key) => {
              return this.props.cantine[key];
            }, {});
      this.props.changePage(`${cantinaObj.url}`, sezioneCorrente, sezionePrecedente, cantinaObj); // aggiorno pagina corrente
    } else if(this.props.router.action === 'POP' && this.props.cantine) {
      if (`/${this.props.sezione.nomePagina}` !== this.props.router.location.pathname) {
        //console.log('[Cantina] aggiorno pagina cantina');
        let currentPath = this.props.location.pathname.split('/');
        let cantinaObj = Object.keys(this.props.cantine)
              .filter(key => this.props.cantine[key]["url"] === currentPath[1])
              .reduce((obj, key) => {
                return this.props.cantine[key];
              }, {});
        this.props.changePage(`${cantinaObj.url}`, sezioneCorrente, sezionePrecedente, cantinaObj);
      }
    }
  }

  onFiltriPer = () => {

    //console.log('onFiltriPer');
    //console.log(this.props.filtri);

    if(!this.props.filtri) {
      return null;
    }

    if (this.props.filtri.cantina !== '' || this.props.filtri.vino !== '' || this.props.filtri.tipologia !== '' || this.props.filtri.formato !== '' || this.props.filtri.confezione !== '') {

      let elencoFiltriSelezionati = '';
      if (this.props.filtri.cantina) elencoFiltriSelezionati += ` ${this.props.filtri.cantina}`;
      if (this.props.filtri.vino) elencoFiltriSelezionati += ` - ${this.props.filtri.vino}`;
      if (this.props.filtri.tipologia) elencoFiltriSelezionati += ` - ${this.props.filtri.tipologia}`;
      if (this.props.filtri.formato) elencoFiltriSelezionati += ` - ${this.props.filtri.formato} ml`;
      if (this.props.filtri.confezione) elencoFiltriSelezionati += ` - ${this.props.filtri.confezione}`;

      return (
        <Container className="filtri">
          <Row noGutters>Filtering by: <b>{elencoFiltriSelezionati}</b></Row>
        </Container>
      );
    } else {
      return null;
    }
  }

  render() {
    let paginaCorrente = this.props.location.pathname.split('/');
    
    return (
      <div>
        <Slideshow sezioneSlide='cantina' paginaSlide={paginaCorrente[1]} />
        <Container>
          <Row noGutters>
            <Col sm={12}>
              <Filtri />
            </Col>
            <Col sm={12} className="filtrandoPer">
              {this.onFiltriPer()}
            </Col>
            <Col sm={12}>
              <ElencoVini />
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    router: state.router,
    sezione: state.sezione,
    cantine: state.catalogo.cantine,
    tipologie: state.catalogo.tipologie,
    etichette: state.catalogo.etichette,
    statico: state.statico,
    filtri: state.filtri.selezionati
  }
}

export default connect(mapStateToProps, { fetchCantine, fetchStatic, fetchTipologie, fetchEtichette, changePage })(Cantina);