import { MENU_OPEN, MENU_CLOSE } from 'store/actions/types';

const INITIAL_STATE = {
    menu: ''
};

export default function(state = INITIAL_STATE, action) {
    switch (action.type) {
        case MENU_OPEN:
            return { ...state, menu: action.payload };
        case MENU_CLOSE:
            return { ...state, menu: action.payload };
        default:
            return state;
    }
}