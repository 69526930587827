import { push } from 'connected-react-router'
import md5 from 'md5';
import axios from 'utility/axiosBase';
import { openDb, deleteDb } from 'idb';
import { MENU_OPEN, MENU_CLOSE, CHANGE_PAGE, FETCH_CANTINE, FETCH_STATIC, FETCH_TIPOLOGIE, FETCH_ETICHETTE, FETCH_VINI, FETCH_FORMATI, FETCH_CONFEZIONI, FETCH_PREZZI, FETCH_ALL, AUTH_LOGIN, AUTH_LOGOUT, IS_AUTH, ERROR_LOGIN, CHANGE_FILTER } from 'store/actions/types';

const arrayStores = ['auth_login', 'cantine', 'prezzi', 'tipologie', 'etichette', 'vini', 'formati', 'confezioni', 'statico', 'blog'];
let storesCreate = [];

// INIZIALIZZO DB
const initializeDb = async () => {
  return await openDb('catalogo', 1, upgradeDB => {
    upgradeDB.createObjectStore('auth_login', { keyPath: 'id', autoIncrement: true });
    upgradeDB.createObjectStore('cantine', { keyPath: 'id', autoIncrement: true });
    upgradeDB.createObjectStore('prezzi', { keyPath: 'id', autoIncrement: true });
    upgradeDB.createObjectStore('tipologie', { keyPath: 'id', autoIncrement: true });
    upgradeDB.createObjectStore('etichette', { keyPath: 'id', autoIncrement: true });
    upgradeDB.createObjectStore('vini', { keyPath: 'ID', autoIncrement: true });
    upgradeDB.createObjectStore('formati', { keyPath: 'id', autoIncrement: true });
    upgradeDB.createObjectStore('confezioni', { keyPath: 'id', autoIncrement: true });
    upgradeDB.createObjectStore('statico', { keyPath: 'id', autoIncrement: true });
    upgradeDB.createObjectStore('blog', { keyPath: 'id', autoIncrement: true });
  });
}

const deleteIDB = async () => {
  return await deleteDb('catalogo');
}

// CONTROLLO CHE ESISTANO TUTTI GLI STORE ALTRIMENTI CANCELLO IL DB E LO RICREO
const checkDb = async () => {
  const idb = await initializeDb().then(db => {
    return db;
  });

  storesCreate = Object.values(idb.objectStoreNames).map(value => {
      return value;
  });

  // controllo che gli stores siano 9, altrimenti cancello il database e lo ricreo
  if (storesCreate.length !== arrayStores.length) {
    //console.log('cancello db');
    deleteIDB();
  }

}

export const menuOpen = () => {
    return {
        type: MENU_OPEN,
        payload: 'OPEN'
    };
};

export const menuClose = () => async (dispatch, getState) => {
  dispatch({
      type: MENU_CLOSE,
      payload: 'CLOSE'
  });
};

export const isAuth = () => async (dispatch, getState) => {

  // CONTROLLO STORES CREATI, SE NON SONO 9 CANCELLO DB E RICREO
  await checkDb();

  // CONTROLLO SE IN IDB c'è un token attivo

  // RECUPERO CONNESSIONE A IDB
  const idb = await initializeDb().then(db => {
    return db;
  });

  // SETTO I PERMESSI IN LETTURA E SCRITTURA
  const tx = await idb.transaction('auth_login', 'readwrite');

  // VERIFICO SE IDB E' POPOLATO E FORNISCO SUBITO UN CONTENUTO OFFLINE --> cache first strategy
  const auth_login = await tx.objectStore('auth_login').getAll();

  if (auth_login.length > 0) {

    let NOW = new Date().getTime();
    NOW = NOW.toString();
    NOW = NOW.substr(0, NOW.length-3);
    NOW = parseInt(NOW);

    // CONTROLLO VALIDITA' TOKEN, SE SCADUTO MANDO EVENTO LOGOUT
    if(NOW > auth_login[0].validita) {
      // SVUOTO LOGIN DI IDB
      tx.objectStore('auth_login').clear();

      //console.log('token scaduto!');

      // ACTION
      dispatch({
        type: AUTH_LOGOUT,
        payload: true
      });
    } else {
      dispatch({
        type: IS_AUTH,
        payload: auth_login[0]
      });
    }

  } else {

  }

}

export const authLogout = () => async (dispatch, getState) => {

  // svuoto idb
  // RECUPERO CONNESSIONE A IDB
  const idb = await initializeDb().then(db => {
    return db;
  });

  // SETTO I PERMESSI IN LETTURA E SCRITTURA
  const tx = await idb.transaction('auth_login', 'readwrite');

  // VERIFICO SE IDB E' POPOLATO E FORNISCO SUBITO UN CONTENUTO OFFLINE --> cache first strategy
  const auth_login = await tx.objectStore('auth_login').getAll();
  if (auth_login.length > 0) {
    tx.objectStore('auth_login').clear();
  }

  // SVUOTO CANTINE, PREZZI E VINI 
  const tx2 = await idb.transaction('cantine', 'readwrite');
  const cantine = await tx2.objectStore('cantine').getAll();
  if (cantine.length > 0) {
    tx2.objectStore('cantine').clear();
  }

  const tx3 = await idb.transaction('prezzi', 'readwrite');
  const prezzi = await tx3.objectStore('prezzi').getAll();
  if (prezzi.length > 0) {
    tx3.objectStore('prezzi').clear();
  }

  const tx4 = await idb.transaction('vini', 'readwrite');
  const vini = await tx4.objectStore('vini').getAll();
  if (vini.length > 0) {
    tx4.objectStore('vini').clear();
  }

  dispatch({
    type: AUTH_LOGOUT,
    payload: true
  });

  dispatch(push('/'));
}

export const authLogin = (authlogin, username) => async (dispatch, getState) => {
  if (authlogin) {
    const authMd5 = md5(authlogin);
    const jsonAuth = {
      "authorizazionPwd": `${authMd5}`
    }

    // RECUPERO CONNESSIONE A IDB
    const idb = await initializeDb().then(db => {
      return db;
    });

    // SETTO I PERMESSI IN LETTURA E SCRITTURA
    const tx = await idb.transaction('auth_login', 'readwrite');

    // VERIFICO SE IDB E' POPOLATO E FORNISCO SUBITO UN CONTENUTO OFFLINE --> cache first strategy
    const auth_login = await tx.objectStore('auth_login').getAll();

    //console.log('[authLogin] initializing')
    if (auth_login.length > 0) {
      //console.log('[authLogin] get cached data from idb');
      // DATI PRESENTI IN IDB --> aggiorno lo store con questi dati
      dispatch({
          type: AUTH_LOGIN,
          payload: auth_login[0]
      });

    }  else {
      // DATI NON PRESENTI IN IDB --> chiamata api a /api/login/
      //console.log('[authLogin] get data from /api/login/ ')

      let response = null;
      try {
        response = await axios.post('/login/', jsonAuth);
      } catch(e) {
        response = e.response;
      }

      let loginObj = null;
      let errorObj = null;

      // NON AUTENTICATO?
      if (response) {
        if (response.data.error) {
          errorObj = { 
            error: response.data.error.status,
            errorMsg: response.data.error.message_en
          }
        } else {
          loginObj = { 
            token: response.data.data.token,
            validita: response.data.data.validita,
            username: username,
            permessicantine: response.data.data.permessicantine
          }
        }
      } else {
        errorObj = {
          error: 'KO',
          errorMsg: 'errore auth'
        }
      }
      

      // APRO NUOVA TRANSACTION A IDB PER AGGIORNARLO CON I DATI DALLA CHIAMATI API --> HO CONSUMATO LA PRIMA PER RECUPERARE IL getAll()
      const tx2 = await idb.transaction('auth_login', 'readwrite');
      if(loginObj) {
        tx2.objectStore('auth_login').clear();
        tx2.objectStore('auth_login').put(loginObj);

       //console.log('[authLogin] updating data into idb');

        // DISPATCH ACTION A REDUCER PER AGGIORNAMENTO STORE CON I DATI AGGIORNATI --> :: network next strategy ::
        dispatch({
          type: AUTH_LOGIN,
          payload: { 
            token: loginObj.token,
            validita: loginObj.validita,
            username: loginObj.username,
            permessicantine: loginObj.permessicantine
          }
        });
      } else {

        // ERRORE LOGIN
        dispatch({
          type: ERROR_LOGIN,
          payload: errorObj
        });
      }
    }
  }
}

export const changeFilter = filters => async (dispatch, getState) => {

  /* console.log(filters);
  console.log(getState);
 */
  dispatch({
    type: CHANGE_FILTER,
    payload: filters
  })

}

export const changePage = (urlPagina, sezionePagina, sezionePrecedente, paginaObj, prezzoId = null) => async (dispatch, getState) => {
  let oldPagina = getState().sezione.nomePagina;
  //console.log('[changePage] oldPagina '+oldPagina);
  //console.log('[changePage] newPage '+urlPagina);

  if (urlPagina !== oldPagina) {

    if (!oldPagina) { oldPagina = urlPagina }

    // action creator
    dispatch({
      type: CHANGE_PAGE,
      payload: { 
        nomePagina: urlPagina,
        sezionePagina: sezionePagina, 
        sezionePrecedente: sezionePrecedente,
        urlPrecedente: oldPagina,
        datiPagina: paginaObj,
        prezzoId: prezzoId
      }
    });

    // redirect a pagina destinazione se diversa da corrente
    if (urlPagina === 'homepage') {
      dispatch(push('/'));
    } else {
      dispatch(push(`/${urlPagina}`));
    }

  }
};

export const fetchAll = () => async (dispatch, getState) => {

  const token = `Bearer ${getState().auth.login.token}`;

  // RECUPERO CONNESSIONE A IDB
  const idb = await initializeDb().then(db => {
    return db;
  });

  // recupero dati da indexedDB
  let tx = await idb.transaction('statico', 'readwrite');
  const staticoCache = await tx.objectStore('statico').getAll();
  let tx2 = await idb.transaction('tipologie', 'readwrite');
  const tipologieCache = await tx2.objectStore('tipologie').getAll();
  let tx3 = await idb.transaction('etichette', 'readwrite');
  const etichetteCache = await tx3.objectStore('etichette').getAll();
  let tx4 = await idb.transaction('cantine', 'readwrite');
  const cantineCache = await tx4.objectStore('cantine').getAll();
  let tx5 = await idb.transaction('vini', 'readwrite');
  const viniCache = await tx5.objectStore('vini').getAll();
  let tx6 = await idb.transaction('formati', 'readwrite');
  const formatiCache = await tx6.objectStore('formati').getAll();
  let tx7 = await idb.transaction('confezioni', 'readwrite');
  const confezioniCache = await tx7.objectStore('confezioni').getAll();
  let tx8 = await idb.transaction('prezzi', 'readwrite');
  const prezziCache = await tx8.objectStore('prezzi').getAll();
  let tx9 = await idb.transaction('blog', 'readwrite');
  const blogCache = await tx9.objectStore('blog').getAll();

  // se ho in indexedDB tutti i dati restituisco prima la cache e poi la aggiorno con le chiamate api
  if(staticoCache.length > 0 && tipologieCache.length > 0 && etichetteCache.length > 0 && cantineCache.length > 0 && viniCache.length > 0 && formatiCache.length > 0 && confezioniCache.length > 0 && prezziCache.length > 0 && blogCache.length > 0) {

    // DISPATCH ACTION A REDUCER PER AGGIORNAMENTO STORE CON I DATI AGGIORNATI --> :: network next strategy ::
    dispatch({
      type: FETCH_ALL,
      payload: [
        staticoCache,
        tipologieCache,
        etichetteCache,
        cantineCache,
        viniCache,
        formatiCache,
        confezioniCache,
        prezziCache,
        blogCache
      ]
    }); 

    // AGGIORNO I DATI CHIAMANDO LE API
    const responseStatic = await axios.get('/static/', { headers: { Authorization: token } } );
    const staticObj = responseStatic.data.data.static;
    if(staticObj) {
      let tx = await idb.transaction('statico', 'readwrite');
      await tx.objectStore('statico').clear();
      await staticObj.map(async statico => {
        tx.objectStore('statico').put(statico);
      });
    }

    const responseTipologie = await axios.get('/tipologie/', { headers: { Authorization: token } } );
    const tipologieObj = responseTipologie.data.data.tipologie;
    if(tipologieObj) {
      let tx = await idb.transaction('tipologie', 'readwrite');
      await tx.objectStore('tipologie').clear();
      await tipologieObj.map(async tipologia => {
        tx.objectStore('tipologie').put(tipologia);
      });
    }

    const responseEtichette = await axios.get('/etichette/', { headers: { Authorization: token } } );
    const etichetteObj = responseEtichette.data.data.etichette;
    if(etichetteObj) {
      let tx = await idb.transaction('etichette', 'readwrite');
      await tx.objectStore('etichette').clear();
      await etichetteObj.map(async etichetta => {
        tx.objectStore('etichette').put(etichetta);
      });
    }
  
    const responseCantine = await axios.get('/cantine/', { headers: { Authorization: token } } );
    const cantineObj = responseCantine.data.data.cantine;
    if(cantineObj) {
      let tx = await idb.transaction('cantine', 'readwrite');
      await tx.objectStore('cantine').clear();
      await cantineObj.map(async cantina => {
        tx.objectStore('cantine').put(cantina);
      });
    }
  
    const responseVini = await axios.get('/vini/', { headers: { Authorization: token } } );
    const viniObj = responseVini.data.data.vini;
    if(viniObj) {
      let tx = await idb.transaction('vini', 'readwrite');
      await tx.objectStore('vini').clear();
      await viniObj.map(async vino => {
        tx.objectStore('vini').put(vino);
      });
    }
  
    const responseFormati = await axios.get('/formati/', { headers: { Authorization: token } } );
    const formatiObj = responseFormati.data.data.formati;
    if(formatiObj) {
      let tx = await idb.transaction('formati', 'readwrite');
      await tx.objectStore('formati').clear();
      await formatiObj.map(async formato => {
        tx.objectStore('formati').put(formato);
      });
    }

    const responseConfezioni = await axios.get('/confezioni/', { headers: { Authorization: token } } );
    const confezioniObj = responseConfezioni.data.data.confezioni;
    if(confezioniObj) {
      let tx = await idb.transaction('confezioni', 'readwrite');
      await tx.objectStore('confezioni').clear();
      await confezioniObj.map(async confezione => {
        tx.objectStore('confezioni').put(confezione);
      });
    }
  
    const responsePrezzi = await axios.get('/prezzi/', { headers: { Authorization: token } } );
    const prezziObj = responsePrezzi.data.data.prezzi;
    if(prezziObj) {
      let tx = await idb.transaction('prezzi', 'readwrite');
      await tx.objectStore('prezzi').clear();
      await prezziObj.map(async prezzo => {
        tx.objectStore('prezzi').put(prezzo);
      });
    }

    const responseBlog = await axios.get('/blog/', { headers: { Authorization: token } } );
    const blogObj = responseBlog.data.data.blog;
    if(blogObj) {
      let tx = await idb.transaction('blog', 'readwrite');
      await tx.objectStore('blog').clear();
      await blogObj.map(async blog => {
        tx.objectStore('blog').put(blog);
      });
    }

  } else {

    // NESSUN DATO A DB --> CHIAMO LE API E POI AGGIORNO indexedDB
    const responseStatic = await axios.get('/static/', { headers: { Authorization: token } } );
    const staticObj = responseStatic.data.data.static;
    if(staticObj) {
      let tx = await idb.transaction('statico', 'readwrite');
      await tx.objectStore('statico').clear();
      await staticObj.map(async statico => {
        tx.objectStore('statico').put(statico);
      });
    }

    const responseTipologie = await axios.get('/tipologie/', { headers: { Authorization: token } } );
    const tipologieObj = responseTipologie.data.data.tipologie;
    if(tipologieObj) {
      let tx = await idb.transaction('tipologie', 'readwrite');
      await tx.objectStore('tipologie').clear();
      await tipologieObj.map(async tipologia => {
        tx.objectStore('tipologie').put(tipologia);
      });
    }

    const responseEtichette = await axios.get('/etichette/', { headers: { Authorization: token } } );
    const etichetteObj = responseEtichette.data.data.etichette;
    if(etichetteObj) {
      let tx = await idb.transaction('etichette', 'readwrite');
      await tx.objectStore('etichette').clear();
      await etichetteObj.map(async etichetta => {
        tx.objectStore('etichette').put(etichetta);
      });
    }
  
    const responseCantine = await axios.get('/cantine/', { headers: { Authorization: token } } );
    const cantineObj = responseCantine.data.data.cantine;
    if(cantineObj) {
      let tx = await idb.transaction('cantine', 'readwrite');
      await tx.objectStore('cantine').clear();
      await cantineObj.map(async cantina => {
        tx.objectStore('cantine').put(cantina);
      });
    }
  
    const responseVini = await axios.get('/vini/', { headers: { Authorization: token } } );
    const viniObj = responseVini.data.data.vini;
    if(viniObj) {
      let tx = await idb.transaction('vini', 'readwrite');
      await tx.objectStore('vini').clear();
      await viniObj.map(async vino => {
        tx.objectStore('vini').put(vino);
      });
    }
  
    const responseFormati = await axios.get('/formati/', { headers: { Authorization: token } } );
    const formatiObj = responseFormati.data.data.formati;
    if(formatiObj) {
      let tx = await idb.transaction('formati', 'readwrite');
      await tx.objectStore('formati').clear();
      await formatiObj.map(async formato => {
        tx.objectStore('formati').put(formato);
      });
    }

    const responseConfezioni = await axios.get('/confezioni/', { headers: { Authorization: token } } );
    const confezioniObj = responseConfezioni.data.data.confezioni;
    if(confezioniObj) {
      let tx = await idb.transaction('confezioni', 'readwrite');
      await tx.objectStore('confezioni').clear();
      await confezioniObj.map(async confezione => {
        tx.objectStore('confezioni').put(confezione);
      });
    }
  
    const responsePrezzi = await axios.get('/prezzi/', { headers: { Authorization: token } } );
    const prezziObj = responsePrezzi.data.data.prezzi;
    if(prezziObj) {
      let tx = await idb.transaction('prezzi', 'readwrite');
      await tx.objectStore('prezzi').clear();
      await prezziObj.map(async prezzo => {
        tx.objectStore('prezzi').put(prezzo);
      });
    }

    const responseBlog = await axios.get('/blog/', { headers: { Authorization: token } } );
    const blogObj = responseBlog.data.data.blog;
    if(blogObj) {
      let tx = await idb.transaction('blog', 'readwrite');
      await tx.objectStore('blog').clear();
      await blogObj.map(async blog => {
        tx.objectStore('blog').put(blog);
      });
    }

    if (staticObj && tipologieObj && etichetteObj && cantineObj && viniObj && formatiObj && confezioniObj && prezziObj && blogObj) {

      // DISPATCH ACTION A REDUCER PER AGGIORNAMENTO STORE CON I DATI AGGIORNATI --> :: network next strategy ::
      dispatch({
        type: FETCH_ALL,
        payload: [
          staticObj,
          tipologieObj,
          etichetteObj,
          cantineObj,
          viniObj,
          formatiObj,
          confezioniObj,
          prezziObj,
          blogObj
        ]
      }); 

    }

  }

}

export const fetchCantine = () => async (dispatch, getState) => {

  const token = `Bearer ${getState().auth.login.token}`;
  
  // RECUPERO CONNESSIONE A IDB
  const idb = await initializeDb().then(db => {
    return db;
  });
  /* console.log(idb); */

  // SETTO I PERMESSI IN LETTURA E SCRITTURA
  const tx = await idb.transaction('cantine', 'readwrite');

  // VERIFICO SE IDB E' POPOLATO E FORNISCO SUBITO UN CONTENUTO OFFLINE --> cache first strategy
  const catalogo = await tx.objectStore('cantine').getAll();

  //console.log('[fetchCantine] initializing')
  if (catalogo.length > 0) {
    //console.log('[fetchCantine] get cached data from idb');
    // DATI PRESENTI IN IDB --> aggiorno lo store con questi dati
    dispatch({
        type: FETCH_CANTINE,
        payload: catalogo
    });

    // RECUPERO I DATI AGGIORNATI DA XHR CANTINE
    const response = await axios.get('/cantine/', { headers: { Authorization: token } } );
    const cantineObj = response.data.data.cantine;
    //console.log('[fetchCantine] updating data from /api/cantine/');

    // APRO NUOVA TRANSACTION A IDB --> HO CONSUMATO LA PRIMA PER RECUPERARE IL getAll()
    const tx2 = await idb.transaction('cantine', 'readwrite');
    if(cantineObj) {
      await cantineObj.map(async cantina => {
        tx2.objectStore('cantine').put(cantina);
      });
    }

  }  else {
    // DATI NON PRESENTI IN IDB --> chiamata api a /api/cantine/
    //console.log('[fetchCantine] get data from /api/cantine/ ')

    const response = await axios.get('/cantine/', { headers: { Authorization: token } } );
    const cantineObj = response.data.data.cantine;
    ////console.log(cantineObj);

    // APRO NUOVA TRANSACTION A IDB PER AGGIORNARLO CON I DATI DALLA CHIAMATI API --> HO CONSUMATO LA PRIMA PER RECUPERARE IL getAll()
    const tx2 = await idb.transaction('cantine', 'readwrite');
    if(cantineObj) {
      await cantineObj.map(async cantina => {
        tx2.objectStore('cantine').put(cantina);
      }); 

      //console.log('[fetchCantine] updating data into idb');

      // DISPATCH ACTION A REDUCER PER AGGIORNAMENTO STORE CON I DATI AGGIORNATI --> :: network next strategy ::
      dispatch({
          type: FETCH_CANTINE,
          payload: cantineObj
      }); 
    }
    
  }

};

export const fetchStatic = () => async (dispatch, getState) => {

  const token = `Bearer ${getState().auth.login.token}`;
  
  // RECUPERO CONNESSIONE A IDB
  const idb = await initializeDb().then(db => {
    return db;
  });

  // SETTO I PERMESSI IN LETTURA E SCRITTURA
  const tx = await idb.transaction('statico', 'readwrite');

  // VERIFICO SE IDB E' POPOLATO E FORNISCO SUBITO UN CONTENUTO OFFLINE --> cache first strategy
  const statico = await tx.objectStore('statico').getAll();

  //console.log('[fetchStatic] initializing')
  if (statico.length > 0) {
    //console.log('[fetchStatic] get cached data from idb');
    // DATI PRESENTI IN IDB --> aggiorno lo store con questi dati
    dispatch({
        type: FETCH_STATIC,
        payload: statico
    });

    // RECUPERO I DATI AGGIORNATI DA XHR PAGINE STATICHE
    const response = await axios.get('/static/', { headers: { Authorization: token } } );
    const staticoObj = response.data.data.static;
    //console.log('[fetchStatic] updating data from /api/static/');

    // APRO NUOVA TRANSACTION A IDB --> HO CONSUMATO LA PRIMA PER RECUPERARE IL getAll()
    const tx2 = await idb.transaction('statico', 'readwrite');
    if(staticoObj) {
      await staticoObj.map(async statico => {
        tx2.objectStore('statico').put(statico);
      });
    }

  }  else {
    // DATI NON PRESENTI IN IDB --> chiamata api a /api/cantine/
    //console.log('[fetchStatic] get data from /api/static/ ')

    const response = await axios.get('/static/', { headers: { Authorization: token } } );
    const staticoObj = response.data.data.static;
    ////console.log(staticoObj);

    // APRO NUOVA TRANSACTION A IDB PER AGGIORNARLO CON I DATI DALLA CHIAMATI API --> HO CONSUMATO LA PRIMA PER RECUPERARE IL getAll()
    const tx2 = await idb.transaction('statico', 'readwrite');
    if(staticoObj) {
      await staticoObj.map(async statico => {
        tx2.objectStore('statico').put(statico);
      }); 

      //console.log('[fetchStatic] updating data into idb');

      // DISPATCH ACTION A REDUCER PER AGGIORNAMENTO STORE CON I DATI AGGIORNATI --> :: network next strategy ::
      dispatch({
          type: FETCH_STATIC,
          payload: staticoObj
      }); 
    }
    
  }

};

export const fetchTipologie = () => async (dispatch, getState) => {

  const token = `Bearer ${getState().auth.login.token}`;
  
  // RECUPERO CONNESSIONE A IDB
  const idb = await initializeDb().then(db => {
    return db;
  });

  // SETTO I PERMESSI IN LETTURA E SCRITTURA
  const tx = await idb.transaction('tipologie', 'readwrite');

  // VERIFICO SE IDB E' POPOLATO E FORNISCO SUBITO UN CONTENUTO OFFLINE --> cache first strategy
  const catalogo = await tx.objectStore('tipologie').getAll();

  //console.log('[fetchTipologie] initializing')
  if (catalogo.length > 0) {
    //console.log('[fetchTipologie] get cached data from idb');
    // DATI PRESENTI IN IDB --> aggiorno lo store con questi dati
    dispatch({
        type: FETCH_TIPOLOGIE,
        payload: catalogo
    });

    // RECUPERO I DATI AGGIORNATI DA XHR TIPOLOGIE
    const response = await axios.get('/tipologie/', { headers: { Authorization: token } } );
    const tipologieObj = response.data.data.tipologie;
    //console.log('[fetchTipologie] updating data from /api/tipologie/');

    // APRO NUOVA TRANSACTION A IDB --> HO CONSUMATO LA PRIMA PER RECUPERARE IL getAll()
    const tx2 = await idb.transaction('tipologie', 'readwrite');
    if(tipologieObj) {
      await tx2.objectStore('tipologie').clear();
      await tipologieObj.map(async tipologia => {
        tx2.objectStore('tipologie').put(tipologia);
      });
    }

  }  else {
    // DATI NON PRESENTI IN IDB --> chiamata api a /api/tipologie/
    //console.log('[fetchTipologie] get data from /api/tipologie/ ')

    const response = await axios.get('/tipologie/', { headers: { Authorization: token } } );
    const tipologieObj = response.data.data.tipologie;
    ////console.log(tipologieObj);

    // APRO NUOVA TRANSACTION A IDB PER AGGIORNARLO CON I DATI DALLA CHIAMATI API --> HO CONSUMATO LA PRIMA PER RECUPERARE IL getAll()
    const tx2 = await idb.transaction('tipologie', 'readwrite');
    if(tipologieObj) {
      await tx2.objectStore('tipologie').clear();
      await tipologieObj.map(async tipologia => {
        tx2.objectStore('tipologie').put(tipologia);
      }); 

      //console.log('[fetchTipologie] updating data into idb');

      // DISPATCH ACTION A REDUCER PER AGGIORNAMENTO STORE CON I DATI AGGIORNATI --> :: network next strategy ::
      dispatch({
          type: FETCH_TIPOLOGIE,
          payload: tipologieObj
      }); 
    }
    
  }

};

export const fetchEtichette = () => async (dispatch, getState) => {

  const token = `Bearer ${getState().auth.login.token}`;
  
  // RECUPERO CONNESSIONE A IDB
  const idb = await initializeDb().then(db => {
    return db;
  });

  // SETTO I PERMESSI IN LETTURA E SCRITTURA
  const tx = await idb.transaction('etichette', 'readwrite');

  // VERIFICO SE IDB E' POPOLATO E FORNISCO SUBITO UN CONTENUTO OFFLINE --> cache first strategy
  const catalogo = await tx.objectStore('etichette').getAll();

  //console.log('[fetchEtichette] initializing')
  if (catalogo.length > 0) {
    //console.log('[fetchEtichette] get cached data from idb');
    // DATI PRESENTI IN IDB --> aggiorno lo store con questi dati
    dispatch({
        type: FETCH_ETICHETTE,
        payload: catalogo
    });

    // RECUPERO I DATI AGGIORNATI DA XHR ETICHETTE
    const response = await axios.get('/etichette/', { headers: { Authorization: token } } );
    const etichetteObj = response.data.data.etichette;
    //console.log('[fetchEtichette] updating data from /api/etichette/');

    // APRO NUOVA TRANSACTION A IDB --> HO CONSUMATO LA PRIMA PER RECUPERARE IL getAll()
    const tx2 = await idb.transaction('etichette', 'readwrite');
    if(etichetteObj) {
      await tx2.objectStore('etichette').clear();
      await etichetteObj.map(async etichetta => {
        tx2.objectStore('etichette').put(etichetta);
      });
    }

  }  else {
    // DATI NON PRESENTI IN IDB --> chiamata api a /api/etichette/
    //console.log('[fetchEtichette] get data from /api/etichette/ ')

    const response = await axios.get('/etichette/', { headers: { Authorization: token } } );
    const etichetteObj = response.data.data.etichette;
    ////console.log(etichetteObj);

    // APRO NUOVA TRANSACTION A IDB PER AGGIORNARLO CON I DATI DALLA CHIAMATI API --> HO CONSUMATO LA PRIMA PER RECUPERARE IL getAll()
    const tx2 = await idb.transaction('etichette', 'readwrite');
    if(etichetteObj) {
      await tx2.objectStore('etichette').clear();
      await etichetteObj.map(async etichetta => {
        tx2.objectStore('etichette').put(etichetta);
      }); 

      //console.log('[fetchEtichette] updating data into idb');

      // DISPATCH ACTION A REDUCER PER AGGIORNAMENTO STORE CON I DATI AGGIORNATI --> :: network next strategy ::
      dispatch({
          type: FETCH_ETICHETTE,
          payload: etichetteObj
      }); 
    }
    
  }

};

export const fetchVini = () => async (dispatch, getState) => {

  const token = `Bearer ${getState().auth.login.token}`;
  
  // RECUPERO CONNESSIONE A IDB
  const idb = await initializeDb().then(db => {
    return db;
  });

  // SETTO I PERMESSI IN LETTURA E SCRITTURA
  const tx = await idb.transaction('vini', 'readwrite');

  // VERIFICO SE IDB E' POPOLATO E FORNISCO SUBITO UN CONTENUTO OFFLINE --> cache first strategy
  const vini = await tx.objectStore('vini').getAll();

  //console.log('[fetchVini] initializing')
  if (vini.length > 0) {
   //console.log('[fetchVini] get cached data from idb');
    // DATI PRESENTI IN IDB --> aggiorno lo store con questi dati
    dispatch({
        type: FETCH_VINI,
        payload: vini
    });

    // RECUPERO I DATI AGGIORNATI DA XHR ETICHETTE
    const response = await axios.get('/vini/', { headers: { Authorization: token } } );
    const viniObj = response.data.data.vini;
    //console.log('[fetchVini] updating data from /api/vini/');

    // APRO NUOVA TRANSACTION A IDB --> HO CONSUMATO LA PRIMA PER RECUPERARE IL getAll()
    const tx2 = await idb.transaction('vini', 'readwrite');
    if(viniObj) {
      await viniObj.map(async vino => {
        tx2.objectStore('vini').put(vino);
      });
    }

  }  else {
    // DATI NON PRESENTI IN IDB --> chiamata api a /api/vini/
    //console.log('[fetchVini] get data from /api/vini/ ')

    const response = await axios.get('/vini/', { headers: { Authorization: token } } );
    const viniObj = response.data.data.vini;
    ////console.log(viniObj);

    // APRO NUOVA TRANSACTION A IDB PER AGGIORNARLO CON I DATI DALLA CHIAMATI API --> HO CONSUMATO LA PRIMA PER RECUPERARE IL getAll()
    const tx2 = await idb.transaction('vini', 'readwrite');
    if(viniObj) {
      await viniObj.map(async vino => {
        tx2.objectStore('vini').put(vino);
      }); 

      //console.log('[fetchVini] updating data into idb');

      // DISPATCH ACTION A REDUCER PER AGGIORNAMENTO STORE CON I DATI AGGIORNATI --> :: network next strategy ::
      dispatch({
          type: FETCH_VINI,
          payload: viniObj
      }); 
    }
  }

};

export const fetchFormati = () => async (dispatch, getState) => {

  const token = `Bearer ${getState().auth.login.token}`;
  
  // RECUPERO CONNESSIONE A IDB
  const idb = await initializeDb().then(db => {
    return db;
  });

  // SETTO I PERMESSI IN LETTURA E SCRITTURA
  const tx = await idb.transaction('formati', 'readwrite');

  // VERIFICO SE IDB E' POPOLATO E FORNISCO SUBITO UN CONTENUTO OFFLINE --> cache first strategy
  const formati = await tx.objectStore('formati').getAll();

  //console.log('[fetchFormati] initializing')
  if (formati.length > 0) {
    //console.log('[fetchFormati] get cached data from idb');
    // DATI PRESENTI IN IDB --> aggiorno lo store con questi dati
    dispatch({
        type: FETCH_FORMATI,
        payload: formati
    });

    // RECUPERO I DATI AGGIORNATI DA XHR ETICHETTE
    const response = await axios.get('/formati/', { headers: { Authorization: token } } );
    const formatiObj = response.data.data.formati;
    //console.log('[fetchFormati] updating data from /api/formati/');

    // APRO NUOVA TRANSACTION A IDB --> HO CONSUMATO LA PRIMA PER RECUPERARE IL getAll()
    const tx2 = await idb.transaction('formati', 'readwrite');
    if(formatiObj) {
      await tx2.objectStore('formati').clear();
      await formatiObj.map(async formato => {
        tx2.objectStore('formati').put(formato);
      });
    }

  }  else {
    // DATI NON PRESENTI IN IDB --> chiamata api a /api/formati/
    //console.log('[fetchFormati] get data from /api/formati/ ')

    const response = await axios.get('/formati/', { headers: { Authorization: token } } );
    const formatiObj = response.data.data.formati;
    ////console.log(formatiObj);

    // APRO NUOVA TRANSACTION A IDB PER AGGIORNARLO CON I DATI DALLA CHIAMATI API --> HO CONSUMATO LA PRIMA PER RECUPERARE IL getAll()
    const tx2 = await idb.transaction('formati', 'readwrite');
    if(formatiObj) {
      await tx2.objectStore('formati').clear();
      await formatiObj.map(async formato => {
        tx2.objectStore('formati').put(formato);
      }); 

      //console.log('[fetchFormati] updating data into idb');

      // DISPATCH ACTION A REDUCER PER AGGIORNAMENTO STORE CON I DATI AGGIORNATI --> :: network next strategy ::
      dispatch({
          type: FETCH_FORMATI,
          payload: formatiObj
      }); 
    }
    
  }

};

export const fetchConfezioni = () => async (dispatch, getState) => {

  const token = `Bearer ${getState().auth.login.token}`;

  // RECUPERO CONNESSIONE A IDB
  const idb = await initializeDb().then(db => {
    return db;
  });

  // SETTO I PERMESSI IN LETTURA E SCRITTURA
  const tx = await idb.transaction('confezioni', 'readwrite');

  // VERIFICO SE IDB E' POPOLATO E FORNISCO SUBITO UN CONTENUTO OFFLINE --> cache first strategy
  const confezioni = await tx.objectStore('confezioni').getAll();

  //console.log('[fetchConfezioni] initializing')
  if (confezioni.length > 0) {
    //console.log('[fetchConfezioni] get cached data from idb');
    // DATI PRESENTI IN IDB --> aggiorno lo store con questi dati
    dispatch({
        type: FETCH_CONFEZIONI,
        payload: confezioni
    });

    // RECUPERO I DATI AGGIORNATI DA XHR ETICHETTE
    const response = await axios.get('/confezioni/', { headers: { Authorization: token } } );
    const confezioniObj = response.data.data.confezioni;
    //console.log('[fetchConfezioni] updating data from /api/confezioni/');

    // APRO NUOVA TRANSACTION A IDB --> HO CONSUMATO LA PRIMA PER RECUPERARE IL getAll()
    const tx2 = await idb.transaction('confezioni', 'readwrite');
    if(confezioniObj) {
      await tx2.objectStore('confezioni').clear();
      await confezioniObj.map(async confezione => {
        tx2.objectStore('confezioni').put(confezione);
      });
    }

  }  else {
    // DATI NON PRESENTI IN IDB --> chiamata api a /api/confezioni/
    //console.log('[fetchConfezioni] get data from /api/confezioni/ ')

    const response = await axios.get('/confezioni/', { headers: { Authorization: token } } );
    const confezioniObj = response.data.data.confezioni;
    ////console.log(confezioniObj);

    // APRO NUOVA TRANSACTION A IDB PER AGGIORNARLO CON I DATI DALLA CHIAMATI API --> HO CONSUMATO LA PRIMA PER RECUPERARE IL getAll()
    const tx2 = await idb.transaction('confezioni', 'readwrite');
    if(confezioniObj) {
      await tx2.objectStore('confezioni').clear();
      await confezioniObj.map(async confezione => {
        tx2.objectStore('confezioni').put(confezione);
      }); 

      //console.log('[fetchConfezioni] updating data into idb');

      // DISPATCH ACTION A REDUCER PER AGGIORNAMENTO STORE CON I DATI AGGIORNATI --> :: network next strategy ::
      dispatch({
          type: FETCH_CONFEZIONI,
          payload: confezioniObj
      }); 
    }
    
  }

};

export const fetchPrezzi = () => async (dispatch, getState) => {

  const token = `Bearer ${getState().auth.login.token}`;
  
  // RECUPERO CONNESSIONE A IDB
  const idb = await initializeDb().then(db => {
    return db;
  });

  // SETTO I PERMESSI IN LETTURA E SCRITTURA
  const tx = await idb.transaction('prezzi', 'readwrite');

  // VERIFICO SE IDB E' POPOLATO E FORNISCO SUBITO UN CONTENUTO OFFLINE --> cache first strategy
  const prezzi = await tx.objectStore('prezzi').getAll();

  //console.log('[fetchPrezzi] initializing')
  if (prezzi.length > 0) {
    //console.log('[fetchPrezzi] get cached data from idb');
    // DATI PRESENTI IN IDB --> aggiorno lo store con questi dati
    dispatch({
        type: FETCH_PREZZI,
        payload: prezzi
    });

    // RECUPERO I DATI AGGIORNATI DA XHR PREZZI
    const response = await axios.get('/prezzi/', { headers: { Authorization: token } } );
    const prezziObj = response.data.data.prezzi;
    //console.log('[fetchPrezzi] updating data from /api/prezzi/');
    ////console.log(prezziObj);

    // APRO NUOVA TRANSACTION A IDB --> HO CONSUMATO LA PRIMA PER RECUPERARE IL getAll()
    const tx2 = await idb.transaction('prezzi', 'readwrite');
    if(prezziObj) {
      await tx2.objectStore('prezzi').clear();
      await prezziObj.map(async prezzo => {
        ////console.log(prezzo);
        tx2.objectStore('prezzi').put(prezzo);
      });
    }

  }  else {
    // DATI NON PRESENTI IN IDB --> chiamata api a /api/prezzi/
    //console.log('[fetchPrezzi] get data from /api/prezzi/ ')

    const response = await axios.get('/prezzi/', { headers: { Authorization: token } } );
    const prezziObj = response.data.data.prezzi;
    ////console.log(prezziObj);

    // APRO NUOVA TRANSACTION A IDB PER AGGIORNARLO CON I DATI DALLA CHIAMATI API --> HO CONSUMATO LA PRIMA PER RECUPERARE IL getAll()
    const tx2 = await idb.transaction('prezzi', 'readwrite');
    if(prezziObj) {
      await tx2.objectStore('prezzi').clear();
      await prezziObj.map(async prezzo => {
        ////console.log(prezzo);
        tx2.objectStore('prezzi').put(prezzo);
      }); 

      //console.log('[fetchPrezzi] updating data into idb');

      // DISPATCH ACTION A REDUCER PER AGGIORNAMENTO STORE CON I DATI AGGIORNATI --> :: network next strategy ::
      dispatch({
          type: FETCH_PREZZI,
          payload: prezziObj
      }); 
    }
    
  }

};