import React, { Component } from 'react';
import { Image } from 'react-bootstrap';
import { connect } from 'react-redux';
import { changePage } from 'store/actions';
import 'components/Pagine/Cantina/ElencoVini/BoxVino/BoxVino.css';

class BoxVino extends Component {

  componentDidMount() {
    ////console.log(process.env.PUBLIC_URL);
  }

  componentDidUpdate() {
    ////console.log(process.env.PUBLIC_URL);
  }

  onRenderVino = () => {
    // recupero dati vino
    let filtroCantina = '';
    /* let filtroVino = ''; */

    if(this.props.filtri) {
      if(this.props.filtri.cantina !== undefined) {
        filtroCantina = this.props.filtri.cantina.toLocaleLowerCase();
      }
      /* if(this.props.filtri.vino !== undefined) {
        filtroVino = this.props.filtri.vino.toLocaleLowerCase();
      } */
    }

    let vinoObjD = null;

    if (filtroCantina && this.props.vini) {
      vinoObjD = this.props.vini;
      ////console.log(vinoObjD);
    } else {
      vinoObjD = this.props.sezione.datiPagina.vini;
    }

    let vinoObj = Object.keys(vinoObjD)
              .filter(key => vinoObjD[key]["ID"] === this.props.vino)
              .reduce((obj, key) => {
                return vinoObjD[key];
              }, {});
    ////console.log(vinoObj);
    if (vinoObj) {
      let idVino = vinoObj.ID;
      let immagineBottiglia = vinoObj.meta.ImmagineBottiglia;
      let nomeVino = vinoObj.Vino;
      let tipologiaVino = '';
      let urlVino = vinoObj.UrlSEO;
      let vinoSelected = '';
      if (this.props.sezione.datiPagina.vinoSelezionato) {
        if (this.props.sezione.datiPagina.vinoSelezionato.ID === idVino) {
          vinoSelected = 'selected';
        }
      }

      // recupero tipologia
      if (this.props.tipologie) {
        let tipologiaObj = Object.keys(this.props.tipologie)
              .filter(key => this.props.tipologie[key]["id"] === vinoObj.meta.Tipologia)
              .reduce((obj, key) => {
                return this.props.tipologie[key];
              }, {});
        ////console.log(tipologiaObj);
        if (tipologiaObj) {
          tipologiaVino = tipologiaObj.tipologia_it;
        }
      }
      return <div style={{...this.props.style}} className={`d-flex flex-column align-items-center justify-content-center ${vinoSelected}`} onClick={() => this.onClickVino(`${urlVino}`)}>
        <Image
          src={`${process.env.PUBLIC_URL}/images/bottiglie/${immagineBottiglia}.png`} 
          alt={nomeVino}
          className="immagineVinoElenco"
        />
        <div className="lineaRossa"></div>
        <h4 className="fsize15">{tipologiaVino}</h4>
        <h3 className="fsize20 fsemib">{nomeVino}</h3>
      </div>;
    }
  }

  onClickVino = urlVino => {
    let sezioneCorrente = this.props.sezione.sezionePagina;
    let sezionePrecedente = this.props.sezione.sezionePrecedente;

    let filtroCantina = '';

    if(this.props.filtri) {
      if(this.props.filtri.cantina !== undefined) {
        filtroCantina = this.props.filtri.cantina.toLocaleLowerCase();
      }
    }

    let vinoObjD = null;
    if (filtroCantina) {
      vinoObjD = this.props.vini;
    } else {
      vinoObjD = this.props.sezione.datiPagina.vini;
    }
    
    //console.log(vinoObjD);
    //console.log(urlVino);
    if (vinoObjD) {
      let vinoObj = Object.keys(vinoObjD)
            .filter(key => vinoObjD[key].UrlSEO === urlVino)
            .reduce((obj, key) => {
              return vinoObjD[key];
            }, {});
      if (vinoObj) {
        ////console.log(vinoObj);
        // url cantina + url vino
        let url = '';
        if (filtroCantina) {
          url = vinoObj.UrlSEO;
        } else {
          url = `${this.props.sezione.datiPagina.url}/${vinoObj.UrlSEO}`;
        }
        // preparo oggetto per dettaglioPagina vino selezionato
        let cantinaObj = '';
        if (filtroCantina) {
          let objCantine = this.props.cantine;
          let cantinaObjSearch = Object.keys(objCantine)
          .filter(key => objCantine[key].cantina.toLocaleLowerCase() === filtroCantina)
          .reduce((obj, key) => {
            return objCantine[key];
          }, {});
          cantinaObj = cantinaObjSearch;
        } else {
          cantinaObj = this.props.sezione.datiPagina;
        }
        
        let vinoSelezionato = {...cantinaObj, vinoSelezionato: vinoObj}
        /* //console.log('aaaaaa');
        //console.log(vinoObj);
        //console.log(vinoSelezionato); */
        this.props.changePage(url, sezioneCorrente, sezionePrecedente, vinoSelezionato); // passo a pagina dettaglio Vino
      }
    }
  }

  render() {
    let boxVino = this.props.sezione.datiPagina.vini ? this.onRenderVino() : '';

    return boxVino;
  }
}

const mapStateToProps = state => {
  return {
    sezione: state.sezione,
    tipologie: state.catalogo.tipologie,
    cantine: state.catalogo.cantine,
    vini: state.catalogo.vini,
    filtri: state.filtri.selezionati 
  }
}

export default connect(mapStateToProps, { changePage })(BoxVino);