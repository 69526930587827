import React, { Component } from 'react';
import { connect } from 'react-redux';
import Slideshow from 'components/UI/Slideshow/Slideshow';
import ElencoCantine from 'components/Pagine/ElencoCantine/ElencoCantine';
import { changePage, fetchStatic, fetchPrezzi, fetchTipologie, fetchEtichette } from 'store/actions';
import 'components/Pagine/Homepage/Homepage.css';

class Homepage extends Component {
  componentDidMount() {
    //console.log('[Homepage] componentDidMount');

    // ricarico Homepage nel caso di back dal browser
    if (this.props.statico.pagine) {
      let paginaObj = Object.keys(this.props.statico.pagine)
            .filter(key => this.props.statico.pagine[key]["pagina"] === 'Homepage')
            .reduce((obj, key) => {
              return this.props.statico.pagine[key];
            }, {});
      ////console.log(paginaObj);
      if (paginaObj) {
        this.props.changePage(paginaObj.url, 'Homepage', 'Homepage', paginaObj);
      }
    }
  }

  componentDidUpdate() {
    //console.log('[Homepage] componentDidUpdate');
    if (this.props.statico.pagine === null && !this.props.sezione.datiPagina) {
      //console.log('[Homepage] componentDidUpdate:: recupero static');
      this.props.fetchStatic(); 
    }

    if (!this.props.sezione.nomePagina && this.props.statico.pagine) {
      //console.log('[Homepage] componentDidUpdate:: recuperato contenuto static aggiorno homepage');
      let paginaObj = Object.keys(this.props.statico.pagine)
            .filter(key => this.props.statico.pagine[key]["pagina"] === 'Homepage')
            .reduce((obj, key) => {
              return this.props.statico.pagine[key];
            }, {});
      ////console.log(paginaObj);
      if (paginaObj) {
        this.props.changePage(paginaObj.url, 'Homepage', 'Homepage', paginaObj);
      }
    }
  }

  render() {
    return (
      <div>
        <Slideshow paginaSlide='homepage' />
        <ElencoCantine />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { 
      sezione: state.sezione,
      statico: state.statico,
      tipologie: state.catalogo.tipologie,
      etichette: state.catalogo.etichette,
      prezzi: state.catalogo.prezzi 
  };
}

export default connect(mapStateToProps, { changePage, fetchStatic, fetchPrezzi, fetchTipologie, fetchEtichette })(Homepage);